import { db } from "../../../../app/firebase";

interface ChangeDeleteReasonProps {
  userID: string;
  reason_report: string;
  reason_report_int: string;
}

export function changeDeleteReason({
  userID,
  reason_report,
  reason_report_int,
}: ChangeDeleteReasonProps) {
  db.collection("banned_moderation").doc(userID).set(
    {
      reason_report_int,
      reason_report,
    },
    { merge: true }
  );
}
