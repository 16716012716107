import { createSlice } from "@reduxjs/toolkit";
interface User {
  uid?: string;
  email?: string;
  moderator?: boolean;
  developer?: boolean;
  loading: boolean;
}

export const userSlice = createSlice<User, any, any>({
  name: "user",
  initialState: {
    loading: true,
  },
  reducers: {
    setUser: function (state: any, action: any) {
      const { uid, email, moderator } = action.payload;

      state.uid = uid;
      state.email = email;
      state.moderator = moderator;
      state.developer = uid === "H8rw3oWoEZZAcIAtVtUB5V82zZF2";
      state.loading = false;
    },
  },
});

export default userSlice.reducer;

export const { setUser } = userSlice.actions;

export const selectUser = (state: any) => state.user;
