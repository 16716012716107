import { Link, Redirect, Route, Switch, useLocation, useRouteMatch } from "react-router-dom";
import HistoryComponent from "./HistoryComponent";
import TextHistoryComponent from "./textHistory/TextHistoryComponent";
import { logAnalytics } from "../../app/firebase";
import { selectUser } from "../auth/userSlice";
import { useSelector } from "react-redux";


export default function ParentHistoryComponent() {
  let { uid } = useSelector(selectUser);

  const location = useLocation()
  const { path, url } = useRouteMatch();

  return <>
    <nav style={{ paddingTop: 0, marginTop: 0 }}>
      <Link onClick={() => logAnalytics({
        event_type: "show_history_webapp",
        data: {
          mod_uid: uid,
          location: "history_post"
        }
      }).catch((err) => console.error("log err", err))}
        to={`${path}/history`}
        className={(location.pathname === `${path}/history` || location.pathname.startsWith(`${path}/history`)) ? "active" : ""}>
        Posts
      </Link>

      <Link onClick={() => {
        logAnalytics({
          event_type: "show_history_webapp",
          data: {
            mod_uid: uid,
            location: "history_text"
          }
        }).catch((err) => console.error("log err", err))
      }}
        to={`${path}/text`}
        className={(location.pathname === `${path}/text` || location.pathname.startsWith(`${path}/text`)) ? "active" : ""}>
        Text
      </Link>
    </nav>

    <Switch>
      <Route exact path={path}>
        <Redirect to={`${path}/history`} />
      </Route>
      <Route path={["/history/history/:userIdURLParam", "/history/history"]}>
        <HistoryComponent />
      </Route>
      <Route path={`/history/text`}>
        <TextHistoryComponent />
      </Route>
    </Switch>
  </>
}
