import dayjs from "dayjs";
import { Card } from "primereact/card";
import { useEffect, useState } from "react";
import { db } from "../../app/firebase";

export function MessagesPreview() {
  const uid = "xWofL4huvQP3MIaUcFGOzQLDd2j2";

  const [recentMessages, setRecentMessages] = useState([]);

  useEffect(() => {
    db.collection("recent_chat")
      .doc(uid)
      .collection("recent_list")
      .orderBy("date", "desc")
      .limit(20)
      .get()
      .then((recentMessages) => {
        setRecentMessages(() => {
          const newRecentMessages = [];

          recentMessages.forEach((message) => {
            newRecentMessages.push({ id: message.id, ...message.data() });
          });
          return newRecentMessages;
        });
      })
      .catch((err) => console.error("Failed fetching recent messages", err));
  }, []);
  return (
    <div>
      <h1>Messages</h1>

      {recentMessages?.map((message) => {
        return (
          <Card className="message" key={message.id} title={message.id}>
            <div className="p-d-flex">
              <b className="p-d-flex">
                <div>{message.userausername}</div>
                <div>-</div>
                <div>{message.userbusername}</div>
              </b>
              <div>{message.message}</div>
              <div> {dayjs(message.date * 1000).fromNow()} </div>
            </div>
          </Card>
        );
      })}
    </div>
  );
}
