import { db, firestore } from "../app/firebase";
import { removeUndefined3 } from "./helpers";

export async function writeHistoryText(param) {
    const { text, type, action, location, mod_uid, user_info, automatic } = param;
    try {
        // console.log("this is text", text);
        let user_info_definded = removeUndefined3(user_info);
        let data = {
            text,
            type,
            action,
            location,
            date: firestore.FieldValue.serverTimestamp(),
            mod_uid,
            user_info: {...user_info_definded},
            automatic
        }
        
        let cleanedData = removeUndefined3(data);
        console.log("Cleaned data after undefined", cleanedData);
        // return 
        let res = await db.collection("history_text")
            .doc()
            .set(cleanedData,
                { merge: true }
            )
        // console.log(res);
    } catch (error) {
        console.error(error);
    }

}