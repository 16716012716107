import { Link, Redirect, Route, Switch, useLocation, useRouteMatch } from "react-router-dom";
import VerificationComponent from "./VerificationComponent";
import AgeVerification from "./ageVerification/AgeVerification";
import { logAnalytics } from "../../app/firebase";
import { selectUser } from "../auth/userSlice";
import { useSelector } from "react-redux";

export default function ParentVerificationComponent() {
  let { uid } = useSelector(selectUser);

  const location = useLocation()
  const { path, url } = useRouteMatch();

  return <>
    <nav style={{ paddingTop: 0, marginTop: 0 }}>
      <Link
        onClick={() => logAnalytics({
          event_type: "show_history_webapp",
          data: {
            mod_uid: uid,
            location: "verification"
          }
        }).catch((err) => console.error("log err", err))}

        to={`${path}/verification`}
        className={(location.pathname === `${path}/verification` || location.pathname.startsWith(`${path}/verification`)) ? "active" : ""}>
        Verification
      </Link>
      <Link
        onClick={() => {
          logAnalytics({
            event_type: "show_history_webapp",
            data: {
              mod_uid: uid,
              location: "age_verif"
            }
          }).catch((err) => console.error("log err", err))
          console.log("Logged age_verif")
        }}
        to={`${path}/age`}
        className={(location.pathname === `${path}/age` || location.pathname.startsWith(`${path}/age`)) ? "active" : ""}>
        Age
      </Link>
    </nav>

    <Switch>
      <Route exact path={path}>
        <Redirect to={`${path}/verification`} />
      </Route>
      <Route path={`/verification/verification`}>
        <VerificationComponent />
      </Route>
      <Route path={`/verification/age`}>
        <AgeVerification />
      </Route>
    </Switch>
  </>
}
