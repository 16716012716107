import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    CircularProgress,
    Button,
} from '@mui/material';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
import { Dropdown } from 'primereact/dropdown';
import { useCallback, useEffect, useRef, useState } from 'react';
import { loadAdmins } from '../../verification/ageVerification/utils/utils';
import { selectUser } from '../../auth/userSlice';
import { useSelector } from 'react-redux';
import { db } from '../../../app/firebase';

const cellTitleStyle = {
    fontSize: '20px'
}
const textCellStyle = {
    fontSize: '18px',
    fontWeight: '500'
}
export default function TextHistoryComponent() {
    const [textData, setTextData] = useState([[]]);
    const [page, setPage] = useState(1);
    const [lastDoc, setLastDoc] = useState(null);
    const [noMoreData, setNoMoreData] = useState(false);
    const [typeFilter, setTypeFilter] = useState('All');
    const [actionFilter, setActionFilter] = useState('All');
    const [userID, setUserID] = useState("");
    let { uid } = useSelector(selectUser);
    const [admins, setAdmins] = useState([{ label: uid, value: uid }]);
    const [adminFilter, setAdminFilter] = useState(uid);
    const [loading, setLoading] = useState(false);
    const [loadingmore, setLoadingMore] = useState(false);
    const observer = useRef();
    const FETCHING_LIMIT = 20;
    const INITIATE_FETCHING_ON_LAST = 2

    const handleTypeFilterChange = (event) => {
        setTypeFilter(event.target.value);
    };

    const handleActionFilterChange = (event) => {
        setActionFilter(event.target.value);
    };

    useEffect(() => {
        loadAdmins(setAdmins);
    }, [loadAdmins, setAdmins]);

    useEffect(() => {
        setTextData([[]]);
        setLoading(true);
        setNoMoreData(false);
        setPage(1);
        fetchTexts();
    }, [adminFilter, typeFilter, actionFilter]);

    const fetchTexts = async (passedUserID) => {
        let res = db.collection("history_text")

        if (adminFilter && adminFilter !== 'All admins') {
            console.log("fetching with admin filter: ", adminFilter)
            res = res.where("mod_uid", "==", adminFilter);
        }

        if (typeFilter && typeFilter !== 'All') {
            console.log("fetching with admin type filter: ", typeFilter)
            res = res.where("type", "==", typeFilter);
        }

        if (actionFilter && actionFilter !== 'All') {
            console.log("fetching with action filter: ", actionFilter);
            res = res.where("action", "==", actionFilter);
        }

        if (userID || passedUserID) {
            res = res.where("user_info.user_id", "==", userID || passedUserID);
        }

        try {
            res = await res.orderBy("date", "desc")
                .limit(FETCHING_LIMIT)
                .get();
            if (res.length === 0) {
                setNoMoreData(true);
                setLoading(false);
                return;
            }

            const texts = res.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            }));

            setLastDoc(res?.docs[res?.docs?.length - 1]);
            setTextData([texts]);
            console.log("These are texts", [texts]);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.error(error);
        }
    }

    const fetchNext = async () => {
        if (loading || noMoreData || loadingmore) return;

        setLoadingMore(true);
        setPage(prev => prev + 1);

        let res = db.collection("history_text")

        if (adminFilter && adminFilter !== 'All admins') {
            console.log("fetching with admin filter: ", adminFilter)
            res = res.where("mod_uid", "==", adminFilter);
        }

        if (typeFilter && typeFilter !== 'All') {
            console.log("fetching with admin type filter: ", typeFilter)
            res = res.where("type", "==", typeFilter);
        }

        if (actionFilter && actionFilter !== 'All') {
            console.log("fetching with action filter: ", actionFilter);
            res = res.where("action", "==", actionFilter);
        }

        if (userID) {
            res = res.where("user_info.user_id", "==", userID);
        }

        res = res.orderBy("date", "desc")
        if (lastDoc) res = res.startAfter(lastDoc);

        try {
            res = await res
                .limit(FETCHING_LIMIT)
                .get();

            if (res.empty) {
                console.log("no more data");
                setNoMoreData(true);
                setLoadingMore(false);
                return;
            }

            const texts = res.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            }));

            setLastDoc(res?.docs[res?.docs?.length - 1]);
            setTextData(prevData => [...prevData, texts]);
            console.log("These are texts", [...textData, texts]);
            setLoadingMore(false);
        } catch (error) {
            setLoadingMore(false);
            console.error(error);
        }

    }

    const handleSearchUser = async (passedUserID) => {
        setUserID(passedUserID);
        fetchTexts(passedUserID);
    }

    const lastElementRef = useCallback(node => {
        if (loading || noMoreData || loadingmore) return;

        if (observer.current) observer.current.disconnect();

        observer.current = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting) {
                fetchNext();
            }
        });

        if (node) observer.current.observe(node);
    }, [fetchNext, loadingmore, noMoreData]);

    return (
        <TableContainer component={Paper} sx={{ padding: '5px' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between', padding: '0 16px', marginBottom: '16px' }}>

                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start' }}>
                    <div style={{ display: 'flex', justifyContent: 'flex-start', gap: '7px' }}>
                        <FormControl sx={{ minWidth: 150 }}>
                            <InputLabel>Type</InputLabel>
                            <Select value={typeFilter} onChange={handleTypeFilterChange} label="Type">
                                <MenuItem value="All">All</MenuItem>
                                <MenuItem value="bio">Bio</MenuItem>
                                <MenuItem value="username">Username</MenuItem>
                                <MenuItem value="caption">Caption</MenuItem>
                            </Select>
                        </FormControl>
                        <FormControl sx={{ minWidth: 120 }}>
                            <InputLabel>Action</InputLabel>
                            <Select value={actionFilter} onChange={handleActionFilterChange} label="Action">
                                <MenuItem value="All">All</MenuItem>
                                <MenuItem value="keep">Keep</MenuItem>
                                <MenuItem value="delete">Delete</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                    <div style={{ marginRight: "auto", marginLeft: "30px", marginTop: "20px" }}>
                        <input
                            type="text"
                            placeholder="Search by User ID"
                            style={{
                                height: "30px",
                                width: "200px",
                                borderRadius: "5px",
                                border: "1px solid #ccc",
                            }}
                            onChange={(e) => handleSearchUser(e.target.value)}
                        // value={reportedFilter}
                        />
                    </div>
                </div>
                {/* <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '7px' }}>
                    <Button sx={{
                        backgroundColor: 'rgba(190, 190, 190, 0.8)',
                        color: 'white',
                        '&:hover': {
                            backgroundColor: 'grey',
                            textDecoration: 'bold'
                        }
                    }}
                        startIcon={<ArrowBackOutlinedIcon />}
                        onClick={() => {
                            if (page > 1) {
                                // setLoading(true);
                                setPage(page - 1);
                            }
                        }}>
                        Back</Button>
                    <Paper sx={{ padding: '5px', width: '35px', height: '35px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        {page}
                    </Paper>
                    <Button sx={{
                        backgroundColor: 'rgba(190, 190, 190, 0.8)',
                        color: 'white',
                        '&:hover': {
                            backgroundColor: 'grey',
                            textDecoration: 'bold'
                        }
                    }}
                        startIcon={<ArrowForwardOutlinedIcon />}
                        onClick={() => {
                            if (loading) return; //to prevent spamming the button
                            else if (page < textData.length) {
                                // setLoading(true);
                                setPage(page + 1);
                            }
                            else if (noMoreData) return;
                            else fetchNext()
                        }}>
                        Next</Button>
                </div> */}
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Dropdown
                        options={admins}
                        onChange={(e) => {
                            setAdminFilter(e.value);

                        }}
                        value={adminFilter}
                        showClear
                        placeholder="All Admins"
                    />
                </div>
            </div>
            {loading ?
                <CircularProgress sx={{
                    position: 'fixed',
                    top: '50%',
                    left: '50%'
                }}
                    size={75}
                />
                :
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell sx={cellTitleStyle}></TableCell>
                            <TableCell sx={cellTitleStyle}>Text</TableCell>
                            <TableCell sx={cellTitleStyle}>Action</TableCell>
                            <TableCell sx={cellTitleStyle}>Type</TableCell>
                            <TableCell sx={cellTitleStyle}>Mod</TableCell>
                            <TableCell sx={cellTitleStyle}>Location</TableCell>
                            <TableCell sx={cellTitleStyle}>User Info</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {textData.flatMap((pageData, pageIndex) =>
                            pageData.map((text, index) => (
                                <TableRow
                                    key={`${pageIndex}-${index}`}
                                    sx={{ backgroundColor: index % 2 === 0 ? '#f5f5f5' : 'white' }}
                                    ref={pageIndex === textData.length - 1 && index === pageData.length - INITIATE_FETCHING_ON_LAST ? lastElementRef : null}
                                >
                                    <TableCell>{index + pageIndex * FETCHING_LIMIT + 1}</TableCell>
                                    <TableCell sx={{ maxWidth: '12vw' }}>
                                        {actionFilter === "All" &&
                                            <span style={{ fontSize: '15px' }}>
                                                {text?.action === 'keep' ? `🟢 ` : `🔴 `}
                                            </span>
                                        }
                                        {text.text}
                                    </TableCell>
                                    <TableCell>{text.action}</TableCell>
                                    <TableCell>{text.type}</TableCell>
                                    <TableCell>{text.mod_uid}</TableCell>
                                    <TableCell>{text.location}</TableCell>
                                    <TableCell>
                                        {text?.user_info?.user_id}, {text?.user_info?.username},{' '}
                                        {text?.automatic ? (
                                            <span style={{ color: 'red', fontWeight: 'bold' }}>Automatic</span>
                                        ) : (
                                            ''
                                        )}
                                    </TableCell>
                                </TableRow>
                            ))
                        )}
                        {loadingmore && (
                            <TableRow>
                                <TableCell colSpan={6} align="center">
                                    Loading...
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            }
        </TableContainer>
    );
};
