import { db, firestore } from "../../../app/firebase";

interface Props {
  reported?: boolean;
}

export function createTest({ reported = false }: Props) {
  let post = {
    ...(reported ? { moderated: false } : {}),
    post_seen: false,
    post_seen_mod: false,
    repost: true,
    img_state: 5,
    finish_processing: true,
    report_view: 2,
    userID: "2TGlDoVX1fSXwgv7u3ZMVd44g622",
    datePost: firestore.FieldValue.serverTimestamp(),
    fcm_token:
      "eXsxyFFZs0UQgBNGX8KjQH:APA91bEe5AFJb-vj-MIgxWmtrdgjpUfoEnahppRDK7_5wgn-lVchqzc_fRUwYwQzjer88fasW094Zm1Ww0RUP35KXHs4r8ZUZmn375LnZaJc0qkpcLS5EnGN2znu77aMmj-sBYGho42R",
    repost_counter: 5,
    report_good: 2,
    username: "SaoTesto",
    country: "🇺🇸",
    age: 22,
    imgInfo: 1,
    postText: "dada",
    emoji: "🤣",
    array_report: [],
    imageUrlPost:
      "https://firebasestorage.googleapis.com/v0/b/addchat_public_posts/o/D6qtfRnvACR31NRcOnKucWRWEVH2%2Fposts%2F94B0ED13-EF75-497A-B2A0-22FD2BEEEBF6.jpg?alt=media&token=2896ec32-38c6-481d-9917-248054804f36",
    gender: 0,
    // boost_request: true,
    // boost_safe: true,
    id: "2TGlDoVX1fSXwgv7u3ZMVd44g622",
  };

  const collection = reported ? "moderation_post" : "public_post";

  console.log("Trying to create post", collection, reported);

  db.collection(collection)
    .doc("2TGlDoVX1fSXwgv7u3ZMVd44g622")
    .set(post)
    .then(() => console.log("Test Post sucessfully added"))
    .catch((err) => {
      console.error("A problem ocurred with creating a post", err);
    });
}
