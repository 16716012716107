import { auth } from "./../../app/firebase";
import { useEffect } from "react";

function SignOutComponent() {
  useEffect(() => {
    auth().signOut().then(console.log("Signed Out")).catch();
  });

  return <></>;
}

export default SignOutComponent;
