import { useEffect, useState, useRef, useCallback } from 'react';
import { Switch, Route, Link, Redirect, useLocation, useRouteMatch, useHistory } from "react-router-dom";
import { selectUser } from '../auth/userSlice';
import VerificationComponent from '../verification/VerificationComponent';
import PostsComponent from '../profile/profileComponent';
import ChatComponent from '../chat/chatComponent';
import { useSelector } from 'react-redux';
import HistoryComponent from '../history/HistoryComponent';
import { setActivePost, selectActivePost } from "../profile/postsSlice";

function SwipingComponent(props) {
    const [activeLink, setActiveLink] = useState('');
    const { path, url } = useRouteMatch();
    const location = useLocation();
    const user = useSelector(selectUser);
    const activePost = useSelector(selectActivePost);

    const history = useHistory();

    function LinkItem({ to, children }) {
        const location = useLocation();
        const isActive = location.pathname === to || location.pathname.startsWith(to);

        return (
            <Link to={to} className={isActive ? "active" : ""}>
                {children}
            </Link>
        );
    }
    useEffect(() => {
        const currentSection = location.pathname.split("/")[2];
        setActiveLink(currentSection);
    }, [location.pathname]);

    //removed because we added Tab
    // const handleKeyUp = useCallback(
    //     (e) => {
    //         if (e.key === 'z' && activePost === -1 ) {
    //             history.push('/swiping/verification');
    //         }
    //         if (e.key === 'x') {
    //             history.push('/swiping/profile');
    //         }
    //         if (e.key === 'c') {
    //             history.push('/swiping/chat');
    //         }
    //     },
    //     [history,activePost]
    // );

    // useEffect(() => {
    //     document.addEventListener("keyup", handleKeyUp);

    //     return function () {
    //         document.removeEventListener("keyup", handleKeyUp);
    //     };
    // }, [handleKeyUp]);
    useEffect(() => {
        // Redirect from /swiping to /swiping/verification
        if(location.pathname == '/swiping' ){
            history.push(`${path}/verification`);
        }
    }, [history, path]);


    return (
        <>
            {user && user.uid && (
                <nav>
                    <LinkItem
                        to={`${path}/verification`}
                    >
                        Verification
                    </LinkItem>

                    <LinkItem
                        to={`${path}/profile`}
                    >
                        Profile
                    </LinkItem>

                    <LinkItem
                        to={`${path}/chat`}
                    >
                        Chat
                    </LinkItem>
                </nav>
            )}


            <Switch>
                {/* <Redirect from="/swiping" to={`${path}/verification`}/> */}
                <Route path={`${path}/verification`}>
                    <VerificationComponent />
                </Route>
                <Route path={`${path}/profile`}>
                    <PostsComponent />
                </Route>
                <Route path={`${path}/chat`}>
                    <ChatComponent />
                </Route>
            </Switch>
        </>
    )
}

export default SwipingComponent