import { logAnalytics } from "../../../../app/firebase";
import { parseUrl } from "../../../../helpers/helpers";

export function logDelete({ event, info, post }) {
  logAnalytics({
    event_type: event,
    info,
    post,

    data: {
      ...info,
      userID: post.userID,
      imageID: parseUrl(post.imageUrlPost),
    },
  }).catch((err) => console.error("log err", err));
}
