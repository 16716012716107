import "./verification.css";

import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { setActivePost, selectActivePost } from "../posts/postsSlice";
import { profileInfoFields } from "./VerificationPost.functions";
dayjs.extend(relativeTime);

export function VerificationPost(props) {
  
  let activePost = useSelector(selectActivePost);
  let dispatch = useDispatch();
// console.log(activePost,"-inverification--active post----",activePost)
  // useEffect(() => {}, [props]);

  // if (activePost !== -1) {
    
    if (activePost == props.postId) {
      // console.log(props,"================props===========Verification post=========");
      props.setId(props.uid);
      props.setUserName(props.name);
      props.setLastPost(props.last_post_url);
      props.setProfileImage(props.imgOne);
    }
  // }

  const accuracyCheck = (stdev) => {
    if(stdev < 3) return 'high'
    if (stdev > 3 && stdev < 4.5) return 'good'
    if (stdev > 4.5) return 'low'
  }

  const ageCheck = () => { 
    if (props?.age - 5 > props?.verif_guess?.age || props?.age + 5 < props?.verif_guess?.age) return 'red_match'
  }
 

  return (
    <div className={activePost == props.postId ? "active" : ""}>
      <div
        className="post"
        onClick={() => {
          dispatch(setActivePost(props.postId));
        }}
      >
        <img className="thumbnail" src={props.imgOne} />
      </div>

      <div className="fullscreen" style={{
          backgroundColor: props?.verif_guess?.age < 16 || props?.verif_guess?.liveliness === 'fake' || props?.verif_guess?.face_match < 80 ? 'rgba(255, 0, 0, 0.35)' : 'rgba(255, 255, 255, 0.9)' 
        }}>
        <div
          onClick={() => dispatch(setActivePost(-1))}
          className="hidden close"
        >
          Close
        </div>
        <div className="images">
          <div>
            {
              props?.app != "addchat" ?  <img
              style={{width:"100%",objectFit:"contain"}}
              className="ref picture"
              src="https://firebasestorage.googleapis.com/v0/b/swiping-6aeb7.appspot.com/o/CAESIBOwPgKOd2GS%2B3POwj%2BZowDDjsTVQmMLtWkIuH7m4DZ%2FverifPic%2F4BC37E29-7716-4998-9814-71013D559E25.jpg?alt=media&token=11a11b0a-8733-48b6-b725-9fbf184c8d6f"
            /> : <img
                style={{width:"100%",objectFit:"contain"}}
                className="ref picture"
                src={props?.last_post_url}
              /> 
            }
          </div>

          <div>
            <img className="hidden picture" src={props.imgVerif} style={{width:"100%",objectFit:"contain"}}/>
          </div>

          <div>
            {
              props?.app == "addchat" &&  props?.version >= 3 ? <img
                style={{width:"100%",objectFit:"contain"}}
                className="ref picture"
                src={props?.imgSign}
              /> : ""
            }
          </div>

          <div>
            <img className="picture" src={props.imgOne}  style={{width:"100%",objectFit:"contain"}} />
          </div> 
        </div>

        <div className="info">
        <div className="ai_info">
            <div className="field">
              <label>age {props?.verif_guess?.age ? (props?.verif_guess?.age < 17 ? '🔴' : '') : ''}:</label>
              <span className={`value ${ageCheck()}`}>{props?.verif_guess?.age ? props.verif_guess.age : 'Ø'}</span>
              <label>accuracy {props?.verif_guess?.st_dev ? (props?.verif_guess?.st_dev > 6 ? '🔴' : '') : ''}:</label>
              <span className="value">{props?.verif_guess?.st_dev ? `${accuracyCheck(props.verif_guess.st_dev)}` : 'Ø'}</span>
            </div>
            <div className="field">
              <label>Face match {props?.verif_guess?.face_match ? (props?.verif_guess?.face_match < 80 ? '🔴' : '') : ''}:</label>
              <span className={`value ${props?.verif_guess?.face_match != null && props?.verif_guess?.face_match < 95 ? 'red_match' : ''}`}>
                {props?.verif_guess?.face_match != null 
                  ? props?.verif_guess?.face_match === 0 
                    ? '🔴  0%' 
                    : `${props?.verif_guess?.face_match}%`
                  : 'Ø'}
              </span>

            </div>
            <div className="field">
              <label>Liveliness:</label>
              <span className="value">{props?.verif_guess?.liveliness ? props.verif_guess.liveliness : 'Ø'}</span>
            </div>
          </div>
          <div className="info_description">
            <span> • {props.age} yo </span>
            <span> • {props.gender ? "F" : "M"} </span>
            <span> • {props.country} </span>
            <span> • {props.name} </span>
            <span>• count: {props.submission_counter} </span>
            <span>• {dayjs.unix(props.date.seconds).fromNow()} </span>
          </div>
        </div>
      </div>
    </div>
  );
};


// export function HistoryPostVerification(props) {
  
//   let activePost = useSelector(selectActivePost);
//   let dispatch = useDispatch();
// // console.log(activePost,"-inverification--active post----",activePost)
//   // useEffect(() => {}, [props]);

//   // if (activePost !== -1) {
    
//     // console.log(props,"================propsHistory====================");
//     if (activePost == props.postId) {
//       props.setId(props.uid);
//       props.setUserName(props.name);
//       props.setLastPost(props.last_post_url);
//       props.setProfileImage(props.imgOne);
//     }
//   // }
 

//   return (
//     <div className={activePost == props.postId ? "active" : ""}>
//       <div
//         className="post"
//         onClick={() => {
//           dispatch(setActivePost(props.postId));
//         }}
//       >
//         <img className="thumbnail" src={props.imgOne} />
//       </div>

//       <div className="fullscreen">
//         <div
//           onClick={() => dispatch(setActivePost(-1))}
//           className="hidden close"
//         >
//           Close
//         </div>

//         {/* <div className="demo">
//           <div >
//             <h3 style={{backgroundColor: "#ffffff",margin: "0",padding: "15px 0"}}> profile Information </h3>
//               <div className="fields">
//                   {profileInfoFields({profileInfo:props})}
//               </div>
//           </div>                
//         </div>  */}

//         <div className="images">
//           <div>
//             {
//               props?.app != "addchat" ?  <img
//               style={{width:"100%",objectFit:"contain"}}
//               className="ref picture"
//               src="https://firebasestorage.googleapis.com/v0/b/swiping-6aeb7.appspot.com/o/CAESIBOwPgKOd2GS%2B3POwj%2BZowDDjsTVQmMLtWkIuH7m4DZ%2FverifPic%2F4BC37E29-7716-4998-9814-71013D559E25.jpg?alt=media&token=11a11b0a-8733-48b6-b725-9fbf184c8d6f"
//               /> : <img
//                 style={{width:"100%",objectFit:"contain"}}
//                 className="ref picture"
//                 src={props?.last_post_url}
//               /> 
//             }
//           </div>

//           <div>
//             <img className="hidden picture" src={props.imgVerif} style={{width:"100%",objectFit:"contain"}}/>
//           </div>
//           <div>
//             <img className="picture" src={props.imgOne}  style={{width:"100%",objectFit:"contain"}} />
//           </div>
//         </div>

//         <div className="info">
//           <div style={{position:"relative"}}>
//             <span> • {props.age} yo </span>
//             <span> • {props.gender ? "F" : "M"} </span>
//             <span> • {props.country} </span>
//             <span> • {props.name} </span>
//             <span>• count: {props.submission_counter} </span>
//           {/* <span>• {dayjs.unix(props.date.seconds).fromNow()} </span> */}
          
//           {
//             props.result === 1 ? <div> <img src="./accepted.png" width="25px" style={{top: "0vw", position: "absolute", right: "-3vw"}} /> </div> : props.result === 2 ? <div> <img src="./rejected.png" width="25px" style={{top: "0vw", position: "absolute", right: "-3vw"}} /> </div> :<div> <img src="./banned.png" width="25px" style={{top: "0vw", position: "absolute", right: "-3vw"}} /> </div> 
//           }
//           </div>
//         </div>
        
        
//         <div className="black-input">
//         {/* <input value={props.by_mod}/> */}
//         <p>{props.by_mod}</p>
//         </div>
        
//       </div>
//     </div>
//   );
// };
