import { Button } from "primereact/button";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { convertToTimestamp, db } from "../../app/firebase";
import { Dropdown } from "primereact/dropdown";
import { useSelector } from "react-redux";
import { ProgressSpinner } from "primereact/progressspinner";
import dayjs from "dayjs";
import "./history.scss";
import HistoryPost from "./HistoryPost";
import { selectUser } from "../auth/userSlice";
import { selectActivePost, setActivePost } from "../posts/postsSlice";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import AnimatedDots from "../../app/components/Animateddots";

function HistoryComponent() {
  let { uid } = useSelector(selectUser);

  const limit = 10;
  const [elements, setElements] = useState([]);
  const [page, setPage] = useState(0);
  const [admins, setAdmins] = useState([{ label: uid, value: uid }]);
  const [adminFilter, setAdminFilter] = useState(uid);
  const [lastPost, setLastPost] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [range, setRange] = useState("");
  const dispatch = useDispatch();
  const right = useRef(null);
  const left = useRef(null);
  const activePost = useSelector(selectActivePost);
  const [actionFilter, setActionFilter] = useState("all");
  const [reasonFilter, setReasonFilter] = useState("all");
  const [genderFilter, setGenderFilter] = useState("all");
  const [reviewMode, setReviewMode] = useState(false);
  const [showReviewConfig, setShowReviewConfig] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [selectedDate, setSelectedDate] = useState("");
  const [selectedShift, setSelectedShift] = useState("");
  const [reviewCount, setReviewCount] = useState(0);
  const [percentageFilter, setPercentageFilter] = useState(0);
  const [counting, setCounting] = useState(false);
  const [reviewPosts, setReviewPosts] = useState({});
  const [score, setScore] = useState({
    accepted: 0,
    rejected: 0,
  });
  const [moderatedCount, setModeratedCount] = useState(0);
  const [reviewedPosts, setReviewedPosts] = useState({});
  const [showResult, setShowResult] = useState(false);
  const [reviewTime, setReviewTime] = useState(0);
  const [FinishedFetchingReview, setFinishedFetchingReview] = useState(false);
  const [numberOfPostsOption, setNumberOfPostsOption] = useState(false);

  const [startTime, setStartTime] = useState();

  const ADMIN_MODS = [
    "8InOiDv72waRp4Nul3aFddOS1ng2",
    "BuT86HiYu1b14bmbffvLv5w7D362",
    "pZn6aKD7GDUVKD1JDJsJW474Azp2",
  ];

  const today = new Date();
  const yyyy = today.getFullYear();
  const mm = String(today.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
  const dd = String(today.getDate()).padStart(2, "0");
  const todayDateString = `${yyyy}-${mm}-${dd}`;

  useEffect(() => {
    if (showResult) setReviewTime(((Date.now() - startTime) / 1000).toFixed(2));
  }, [showResult]);

  const acceptedCount = useMemo(() => {
    return Object.values(reviewedPosts).filter((status) => status).length;
  }, [reviewedPosts, activePost]);

  const rejectedCount = useMemo(() => {
    console.log(
      "rejectedCount: ",
      Object.values(reviewedPosts).filter((status) => !status).length
    );
    return Object.values(reviewedPosts).filter((status) => !status).length;
  }, [reviewedPosts, activePost]);

  // Compute Mod Score
  const modScore = useMemo(() => {
    if (acceptedCount === 0) return "N/A"; // Avoid division by zero
    const scoree = (acceptedCount / (elements[page]?.length || 10)) * 100;
    return `${Math.floor(scoree)}%`;
  }, [acceptedCount, rejectedCount]);

  const actionOptions = [
    { label: "All", value: "all" },
    { label: "Keep", value: "keep" },
    { label: "Delete", value: "delete" },
    { label: "Ban", value: "ban" },
  ];
  const genderOptions = [
    { label: "All", value: "all" },
    { label: "guys", value: 0 },
    { label: "girls", value: 1 },
  ];
  const reasonOptions = [
    { label: "All", value: "all" },
    { label: "Against Guidelines", value: 0 },
    { label: "Nudity", value: 1 },
    { label: "Underage", value: 2 },
    { label: "Selling / Self-Promotion", value: 3 },
    { label: "Sexually Suggestive", value: 4 },
    { label: "Scam", value: 5 },
    { label: "Illegal", value: 6 },
  ];
  const numberOfPostsOptions = [
    { label: "120", value: "120" },
    { label: "270", value: "270" },
    { label: "350", value: "350" },
    { label: "420", value: "420" },
  ];

  const shifts = [
    {
      name: "AM",
      start: "06:00",
      end: "15:00",
    },
    {
      name: "PM",
      start: "15:00",
      end: "00:00",
    },
    {
      name: "NIGHT",
      start: "00:00",
      end: "06:00",
    },
  ];

  const showClearIcon = actionFilter !== "";

  const { userIdURLParam } = useParams();
  const [userFilter, setUserFilter] = useState(userIdURLParam || "");

  // useEffect(() => {
  //   let myTimestamp = convertToTimestamp(new Date(range));
  //   let timestamp = convertToTimestamp(new Date(range));
  // }, [range]);
  // .where('date','>=', start).where('date','<=', end)
  //
  function fetchNext() {
    setIsLoading(true);

    let historyCollection = db.collection("history");

    if (userFilter) {
      setActionFilter("all");
      setAdminFilter("");
      setGenderFilter("all");
      historyCollection = historyCollection.where(
        "post_data.userID",
        "==",
        userFilter
      );
    } else {
      if (adminFilter) {
        historyCollection = historyCollection.where(
          "mods",
          "array-contains",
          adminFilter ? adminFilter : "undefined"
        );
      }
      if (actionFilter != "all") {
        historyCollection = historyCollection.where(
          "action",
          "==",
          actionFilter
        );
      }

      if (reasonFilter != "all") {
        historyCollection = historyCollection.where(
          "reason_int",
          "==",
          reasonFilter
        );
      }

      if (genderFilter != "all") {
        historyCollection = historyCollection.where(
          "post_data.gender",
          "==",
          genderFilter
        );
        console.log("historyCollection", genderFilter);
      }

      if (range) {
        historyCollection = historyCollection.where(
          "date",
          "<=",
          new Date(range)
        );
      }
    }

    historyCollection = historyCollection.orderBy("date", "desc");
    if (lastPost) {
      console.log(lastPost, "--------work true when lastpost");
      historyCollection = historyCollection.startAfter(lastPost);
    }
    console.log(historyCollection, "hisss");

    historyCollection
      .limit(limit)
      .get()
      .then((snapshot) => {
        const historyData = [];

        snapshot.forEach((element) => {
          historyData.push(element.data());
        });

        setLastPost(snapshot.docs[snapshot.docs.length - 1]);
        console.log(
          historyData,
          "-----------net in history -----",
          snapshot.docs,
          snapshot.docs[snapshot.docs.length - 1]
        );
        setElements((elements) => [...elements, historyData]);
        setIsLoading(false);
      });
  }

  // console.log("-------------------main page last post--------", lastPost);

  function nextPage() {
    console.log("review mode", reviewMode);
    if (reviewMode) return;
    console.log("----------------------ooooo---------");
    if (page + 1 >= elements.length) {
      console.log("----------------------ooooo---- innnn-----", lastPost);
      fetchNext();
    }

    setPage((page) => page + 1);
  }

  function previousPage() {
    console.log("----------------------ooooo---------");
    setPage((page) => {
      if (page - 1 >= 0) {
        return page - 1;
      } else {
        return page;
      }
    });
  }

  // const handleKeyUp = useCallback(
  //   (e) => {
  //     console.log(activePost,"outer------ key down");
  //     if (e.key === " " && activePost === -1) {
  //       dispatch(setActivePost(0));
  //     };

  //     if (activePost === -1) {
  //       console.log(activePost,"outer------ key down---deep in");
  //       if (e.key === "g") {
  //         previousPage();
  //       }

  //       if (e.key === "h") {
  //         nextPage();
  //       }
  //       if (e.key === "ArrowRight") {
  //         nextPage();
  //       }

  //       if (e.key === "ArrowLeft") {
  //         previousPage();
  //       }
  //     }
  //   },
  //   [activePost, dispatch]
  // );
  const handleKeyUp = (e) => {
    console.log(activePost, "outer------ key down");
    if (e.key === " " && activePost === -1) {
      dispatch(setActivePost(0));
    }

    if (activePost === -1) {
      console.log(activePost, "outer------ key down---deep in");

      // add escape button
      if (e.key === "ArrowRight") {
        right.current.click();
        // nextPage();
      }

      if (e.key === "ArrowLeft") {
        left.current.click();
        // previousPage();
      }

      if (e.key === "Escape") {
        setShowReviewConfig(false);
      }

      if (e.key === "g") {
        left.current.click();
        // previousPage();
      }

      if (e.key === "h") {
        right.current.click();
        // nextPage();
      }
    }
  };

  const preloadImage = (src) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.src = src;
      img.onload = resolve;
      img.onerror = reject;
    });
  };

  const preloadImages = (imagesArray) => {
    const promises = imagesArray.map((src) => preloadImage(src));
    return Promise.all(promises);
  };

  useEffect(() => {
    if (activePost === -1) {
      // console.log("----event outer");
      document.addEventListener("keyup", handleKeyUp);
    } else {
      if (activePost !== -1) {
        let imagesToPreload = [
          elements[page][activePost + 1]?.imageUrlPost,
          elements[page][activePost + 1]?.profile_pic_url,
          elements[page][activePost + 1]?.verification_url,
          elements[page][activePost + 2]?.imageUrlPost,
          elements[page][activePost + 2]?.profile_pic_url,
          elements[page][activePost + 2]?.verification_url,
        ].filter((url) => !!url); // Filter out any undefined or null values
        if (imagesToPreload.length > 0) {
          // console.log("prefetching :")
          // console.log("urls:", imagesToPreload);
          preloadImages(imagesToPreload)
            .then(() => {
              console.log("Successfully prefetched next images.");
            })
            .catch((error) => {
              console.error("Failed to preload next images:", error);
            });
        }
      }
    }

    return function () {
      document.removeEventListener("keyup", handleKeyUp);
    };
  }, [activePost]);

  const handleSetReviewedPosts = useCallback((postID, isAccepted) => {
    setReviewedPosts((prev) => {
      const updatedReviewedPosts = {
        ...prev,
        [postID]: isAccepted,
      };
      console.log("reviewedPosts updated:", updatedReviewedPosts);
      return updatedReviewedPosts;
    });
  }, []);

  const handleUndoReview = useCallback((postId) => {
    setReviewedPosts((prev) => {
      const { [postId]: removed, ...rest } = prev;
      console.log("reviewedPosts after undo:", rest);
      return rest;
    });
  }, []);

  // console.log(elements, "----data for difront page-----------");
  const HistoryPosts = useCallback(() => {
    return (
      <>
        {(elements[page] ?? [])?.map((historyPost, index, ara) => {
          {
            // console.log(ara, "-------in maping---------", page);
          }
          return (
            <HistoryPost
              key={index}
              postId={index}
              history={historyPost}
              changed={historyPost.changed}
              {...historyPost.post_data}
              post={historyPost.post_data}
              imageID={historyPost.imageID}
              onChange={() => setChanged(historyPost.imageID)}
              limit={reviewMode ? elements[page]?.length || 0 : limit}
              nextPage={nextPage}
              previousPage={previousPage}
              page={page}
              userFilter={userFilter}
              reviewMode={reviewMode}
              score={score}
              setScore={setScore}
              setModeratedCount={setModeratedCount}
              setAdminFilter={setAdminFilter}
              setReviewMode={setReviewMode}
              reviewedPost={reviewedPosts?.hasOwnProperty(index)}
              setReviewedPosts={handleSetReviewedPosts}
              undoReviewedPost={handleUndoReview}
              showResult={showResult}
              setShowResult={setShowResult}
            />
          );
        })}
      </>
    );
  }, [elements, page, reviewMode, reviewedPosts]);

  // useEffect(()=>{
  //   if(score.accepted > 0 || score.rejected > 0){
  //     dispatch(setActivePost(activePost + 1))
  //   }
  // },[score])

  const initialFetch = () => {
    setIsLoading(true);
    setPage(0);

    let historyCollection = db.collection("history");

    if (userFilter) {
      setActionFilter("all");
      setAdminFilter("");
      setGenderFilter("all");
      historyCollection = historyCollection.where(
        "post_data.userID",
        "==",
        userFilter
      );
    } else {
      if (adminFilter) {
        historyCollection = historyCollection.where(
          "mods",
          "array-contains",
          adminFilter
        );
      }

      if (actionFilter != "all") {
        historyCollection = historyCollection.where(
          "action",
          "==",
          actionFilter
        );
        // console.log("historyCollection", historyCollection);
      }

      if (reasonFilter != "all") {
        historyCollection = historyCollection.where(
          "reason_int",
          "==",
          reasonFilter
        );
        // console.log("historyCollection", historyCollection);
      }

      if (genderFilter != "all") {
        historyCollection = historyCollection.where(
          "post_data.gender",
          "==",
          genderFilter
        );
        console.log("historyCollection", genderFilter);
      }

      if (range) {
        historyCollection = historyCollection.where(
          "date",
          "<=",
          new Date(range)
        );
      }
    }

    // historyCollection = historyCollection.orderBy("date", "desc");
    historyCollection = historyCollection.orderBy("date", "desc");
    // console.log(historyCollection, "hisss")

    historyCollection
      .limit(limit)
      .get()
      .then((snapshot) => {
        const historyData = [];

        snapshot?.forEach((element) => {
          historyData.push(element?.data());
        });
        console.log("these are the history posts:", historyData);
        setLastPost(snapshot.docs[snapshot.docs.length - 1]);
        setElements([historyData]);
        setIsLoading(false);
      });
  };

  const initialFetchByDate = () => {
    setIsLoading(true);
    setPage(0);
    let historyCollection = db.collection("history");

    if (userFilter) {
      setActionFilter("all");
      setAdminFilter("");
      setGenderFilter("all");
      historyCollection = historyCollection.where(
        "post_data.userID",
        "==",
        userFilter
      );
    } else {
      if (adminFilter) {
        historyCollection = historyCollection.where(
          "mods",
          "array-contains",
          adminFilter
        );
      }

      if (actionFilter != "all") {
        historyCollection = historyCollection.where(
          "action",
          "==",
          actionFilter
        );
        console.log("historyCollection", historyCollection);
      }

      if (reasonFilter != "all") {
        historyCollection = historyCollection.where(
          "reason_int",
          "==",
          reasonFilter
        );
        // console.log("historyCollection", historyCollection);
      }

      if (genderFilter != "all") {
        historyCollection = historyCollection.where(
          "post_data.gender",
          "==",
          genderFilter
        );
        console.log("historyCollection", genderFilter);
      }

      if (range) {
        historyCollection = historyCollection.where(
          "date",
          "<=",
          new Date(range)
        );
        // .where("date", "<=", new Date());
      }
    }

    historyCollection = historyCollection.orderBy("date", "desc");
    // console.log(historyCollection, "hisss")
    historyCollection
      .limit(limit)
      .get()
      .then((snapshot) => {
        const historyData = [];

        snapshot.forEach((element) => {
          historyData.push(element.data());
        });
        console.log(historyData[0], "historyData");
        setLastPost(snapshot.docs[snapshot.docs.length - 1]);
        setElements([historyData]);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    initialFetch();
    console.log("this is userFilter", userFilter);
  }, [
    range,
    adminFilter,
    actionFilter,
    reasonFilter,
    genderFilter,
    userFilter,
  ]);

  // useEffect(() => {
  //   if (userFilter) initialFetch();
  // }, [userFilter])

  // useEffect(initialFetch, [adminFilter]);
  // useEffect(initialFetch, [actionFilter]);
  // useEffect(initialFetch, [reasonFilter]);
  // useEffect(initialFetch, [genderFilter]);

  function setChanged(imageID) {
    setElements((elements) => {
      const newElements = elements.map((element) => {
        if (element.imageID === imageID) {
          element.changed = true;
        }
        return element;
      });

      return newElements;
    });
  }

  function loadAdmins() {
    const adminsArray = [];
    db.collection("admin")
      .where("admin", "==", true)
      .get()
      .then((snapshot) => {
        snapshot.forEach((admin) => {
          const { userID } = admin.data();
          // console.log(userID, "-------------------userid");
          adminsArray.push({ label: userID, value: userID });
        });
        setAdmins((admins) => [...admins, ...adminsArray]);
      });
  }

  useEffect(loadAdmins, []);

  function reset() {
    setLastPost(null);
    setPage(0);
    initialFetch();
  }

  function PageNavigation() {
    return (
      <div className="page-navigation">
        <Button onClick={previousPage} label="Previous" ref={left} />
        <Button label={page + 1} className="p-button-outlined " />
        <Button onClick={nextPage} label="next" ref={right} />
      </div>
    );
  }

  const countReviewHistory = async () => {
    if (!startDate) return alert("Please select a start date");
    if (!endDate) return alert("Please select an end date");

    try {
      // setCounting(true);
      // const query = db
      //   .collection("history")
      //   .where("mod_uid", "==", adminFilter)
      //   .where("datePost", ">=", startDate)
      //   .where("datePost", "<=", endDate);

      // // const countSnapshot = await query.count().get();
      // // const count = countSnapshot.data().count;

      // const countSnapshot = await query.get();
      // const count = countSnapshot.docs.length;
      // console.log("count", count);
      const count = 1000;
      setReviewCount(count);
      setPercentageFilter(Math.round(0.1 * count));
    } catch (error) {
      alert("Error counting reviews:", error);
      console.log("Error counting reviews:", error);
    } finally {
      // setCounting(false);
    }
  };

  const fetchReviewHistory = async (
    startDate,
    endDate,
    numberOfPostsOption
  ) => {
    if (!startDate) return alert("Please select a start date");
    if (!endDate) return alert("Please select an end date");
    if (!adminFilter) return alert("Please select an admin");

    const fetchConfig = {
      120: {
        limit: 12,
        interval: 10,
      },
      270: {
        limit: 27,
        interval: 10,
      },
      350: {
        limit: 35,
        interval: 10,
      },
      420: {
        limit: 42,
        interval: 10,
      },
    };

    const { limit, interval } = fetchConfig[numberOfPostsOption];

    try {
      setIsLoading(true);

      // Convert startDate and endDate to Date objects
      const startDateTime = startDate;
      const endDateTime = endDate;

      if (isNaN(startDateTime.getTime()) || isNaN(endDateTime.getTime())) {
        return alert("Invalid date format. Please provide valid dates.");
      }

      // Calculate total duration and interval duration
      const totalDurationMs = endDateTime.getTime() - startDateTime.getTime();
      const intervalDurationMs = totalDurationMs / interval;

      let currentIntervalEnd = endDateTime; // Start from the end date
      const map = new Map();
      let posts = [];
      let extraPostsNeeded = 0;
      const LIMIT = limit;

      for (let i = 0; i < interval; i++) {
        // Calculate the end of the current interval
        const currentIntervalStart = new Date(
          currentIntervalEnd.getTime() - intervalDurationMs
        );

        console.log("fetched starting from:", currentIntervalEnd);

        // Use the interval end as the minuteEnd for the query
        const queryEndTime = currentIntervalEnd;

        // Query posts before the end of the interval
        const query = db
          .collection("history")
          .where(
            "mods",
            "array-contains",
            adminFilter ? adminFilter : "undefined"
          )
          .where("date", "<=", queryEndTime)
          .orderBy("date", "desc")
          .limit(LIMIT + extraPostsNeeded);

        const snapshot = await query.get();

        snapshot.docs.forEach((doc, index) => {
          const postData = doc.data();
          const uniqueField = postData?.post_data?.imageUrlPost;
          if (map.length === numberOfPostsOption) return;
          if (!map.has(uniqueField)) {
            map.set(uniqueField, postData);
            if (index > LIMIT && extraPostsNeeded > 0) {
              extraPostsNeeded--;
            }
          } else {
            console.log("duplicate post found", uniqueField);
            if (index <= LIMIT) extraPostsNeeded++;
            return;
          }

          posts.push(postData);
        });
        console.log(
          "batch number",
          i,
          "arrived with",
          snapshot.docs.length,
          "posts"
        );
        setElements([posts]);
        if (i === 0) {
          setStartTime(Date.now());
          dispatch(setActivePost(0));
          setShowReviewConfig(false);
          setReviewMode(true);
          setIsLoading(false);
        }
        // Update currentIntervalEnd for the next iteration
        currentIntervalEnd = currentIntervalStart;
      }

      setFinishedFetchingReview(true);

      const uniquePosts = Array.from(map.values());
      console.log(
        "There are",
        uniquePosts.length,
        "unique posts, and all fetched posts are",
        posts.length
      );
    } catch (error) {
      alert("Error fetching Posts:", error);
      console.log("Error fetching Posts:", error);
    }
  };

  const formatDate = (dateString) => {
    if (!dateString) return ""; // Handle empty or undefined date strings

    const options = {
      year: "numeric",
      month: "long", // Full month name (e.g., "October")
      day: "numeric",
    };

    const date = new Date(dateString);

    // Check for invalid dates
    if (isNaN(date)) {
      return "Invalid Date";
    }

    return date.toLocaleDateString(undefined, options);
  };

  const formatTime = (dateString) => {
    if (!dateString) return ""; // Handle empty or undefined date strings

    const options = {
      hour: "numeric",
      minute: "2-digit",
      hour12: true, // 12-hour format (AM/PM)
    };

    const date = new Date(dateString);

    // Check for invalid dates
    if (isNaN(date)) {
      return "Invalid Time";
    }

    return date.toLocaleTimeString(undefined, options);
  };

  const formatTimeTaken = (reviewTime) => {
    const minutes = Math.floor(reviewTime / 60);
    const seconds = Math.floor(reviewTime % 60);

    if (minutes === 0) {
      return `${seconds} secs`;
    } else {
      return `${minutes} min${minutes > 1 ? "s" : ""} ${seconds} sec${
        seconds !== 1 ? "s" : ""
      }`;
    }
  };

  const handleSubmit = () => {
    if (!selectedDate || !selectedShift) {
      alert("Please select both date and shift.");
      return;
    }

    const shift = shifts.find((s) => s.name === selectedShift);
    if (!shift) {
      alert("Invalid shift selected.");
      return;
    }

    if (!numberOfPostsOption) {
      alert("Please select a number of posts to review");
      return;
    }

    const startDateTime = new Date(`${selectedDate}T${shift.start}`);
    console.log("start date time:", startDateTime);
    setStartDate(startDateTime);
    const endDateTime =
      shift.end === "00:00"
        ? new Date(
            new Date(`${selectedDate}T${shift.end}`).getTime() +
              24 * 60 * 60 * 1000
          )
        : new Date(`${selectedDate}T${shift.end}`);
    console.log("end date time:", endDateTime);
    setEndDate(endDateTime);

    fetchReviewHistory(startDateTime, endDateTime, numberOfPostsOption);
  };
  console.log("ADMIN_MOD:", ADMIN_MODS.includes(uid));
  return (
    <div className="history">
      {reviewMode && (
        <>
          <div
            style={{
              position: "relative",
              fontSize: "30px",
              zIndex: 900,
              position: "fixed",
              bottom: 5,
              right: 30,
            }}
          >
            {Object.keys(reviewedPosts).length === elements[0]?.length && (
              <Button
                label="Show Score"
                onClick={() => {
                  setShowResult(true);
                }}
                style={{
                  backgroundColor: "white",
                  color: "black",
                  borderRadius: "10px",
                  padding: "10px 20px",
                  fontSize: "16px",
                  fontWeight: "bold",
                  cursor: "pointer",
                  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
                  marginRight: "10px",
                  // "&:hover": {
                  //   backgroundColor: "grey",
                  //   color: "white",
                  // },
                }}
              />
            )}
            🟢
          </div>
          <div
            style={{
              position: "fixed",
              textAlign: "center",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontSize: "25px",
              zIndex: 900,
              bottom: 5,
              left: 35,
            }}
          >
            {Object.keys(reviewedPosts).length}/
            {elements ? `${elements[0].length}` : "?"}
            {!FinishedFetchingReview && <AnimatedDots />}
          </div>
        </>
      )}
      {showReviewConfig && (
        <div
          style={{
            backgroundColor: "rgba(0, 0, 0, 0.4)",
            width: "100vw",
            height: "100vh",
            zIndex: 900,
            position: "fixed",
            top: 0,
            left: 0,
          }}
        >
          <div
            className="chatpopup_container"
            style={{
              position: "fixed", // Or 'absolute', if you prefer
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)", // Centers the div
              width: "20vw",
              border: "2px solid grey",
              borderRadius: "10px",
              backgroundColor: "white",
              zIndex: 900,
              // Ensure content is scrollable if it overflows
              height: "50vh",
            }}
          >
            <button
              className="close"
              style={{ position: "sticky" }}
              onClick={() => setShowReviewConfig(false)}
            >
              Close
            </button>
            {/* <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                height: "85%",
                border: "1px solid black",
              }}
            > */}
            <div
              style={{
                // border: "1px solid red",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "space-around",
                width: "100%",
                height: "85%",
              }}
            >
              <h2>Fill Below to start</h2>
              <div style={{ marginBottom: "15px" }}>
                <label htmlFor="date">Select Date:</label>
                <input
                  id="date"
                  type="date"
                  value={selectedDate}
                  max={todayDateString}
                  onChange={(e) => {
                    setSelectedDate(e.target.value);
                  }}
                  style={{
                    marginLeft: "10px",
                    height: "28px",
                    padding: "2px",
                  }}
                />
              </div>

              <div style={{ marginBottom: "15px" }}>
                <label htmlFor="shift">Select Shift:</label>
                <select
                  id="shift"
                  value={selectedShift}
                  onChange={(e) => {
                    console.log(
                      "Selected Shift:",
                      shifts.find((s) => s.name === e.target.value)
                    );
                    setSelectedShift(e.target.value);
                  }}
                  style={{
                    marginLeft: "10px",
                    height: "34px",
                    padding: "2px",
                  }}
                >
                  <option value="">-- Select Shift --</option>
                  {shifts.map((shift) => (
                    <option key={shift.name} value={shift.name}>
                      {shift.name} ({shift.start} - {shift.end})
                    </option>
                  ))}
                </select>
              </div>
              <div style={{ marginBottom: "15px" }}>
                <label htmlFor="shift">Select # Posts:</label>
                <select
                  id="shift"
                  value={numberOfPostsOption}
                  onChange={(e) => {
                    setNumberOfPostsOption(e.target.value);
                  }}
                  style={{
                    marginLeft: "10px",
                    height: "34px",
                    padding: "2px",
                  }}
                >
                  <option value="">-- Select # Posts --</option>
                  {numberOfPostsOptions.map((option) => (
                    <option key={option.label} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
              </div>
              <Button
                label="Start Review"
                style={
                  selectedDate && selectedShift
                    ? { backgroundColor: "#5555AA" }
                    : {}
                }
                onClick={handleSubmit}
                disabled={!selectedDate || !selectedShift}
              />
            </div>
            {/* </div> */}
          </div>
        </div>
      )}
      {showResult && (
        <div
          style={{
            backgroundColor: "rgba(0, 0, 0, 0.4)",
            width: "100vw",
            height: "100vh",
            zIndex: 900,
            position: "fixed",
            top: 0,
            left: 0,
          }}
        >
          <div
            className="chatpopup_container"
            style={{
              position: "fixed", // Or 'absolute', if you prefer
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)", // Centers the div
              width: "40vw",
              border: "2px solid grey",
              borderRadius: "10px",
              backgroundColor: "white",
              zIndex: 900,
              // Ensure content is scrollable if it overflows
              height: "50vh",
            }}
          >
            <button
              className="close"
              style={{ position: "sticky" }}
              onClick={() => setShowResult(false)}
            >
              Close
            </button>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center", // Center items vertically
                width: "100%",
                height: "85%",
                padding: "20px", // Add padding for better spacing
                boxSizing: "border-box", // Ensure padding doesn't affect overall size
                backgroundColor: "#f9f9f9", // Optional: Add a background color for contrast
              }}
            >
              <h2 style={{ marginBottom: "20px", color: "#333" }}>
                Finished Review for {adminFilter}
              </h2>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px", // Space between each info item
                  width: "100%",
                  maxWidth: "400px", // Limit the width for better readability
                  textAlign: "left", // Align text to the left
                  marginBottom: "30px", // Space before the button
                }}
              >
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <span>Time Taken:</span>
                  <span>{formatTimeTaken(reviewTime)}</span>
                </div>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                    maxWidth: "500px", // Adjust as needed
                    margin: "0 auto", // Center the container
                    padding: "10px 0", // Vertical padding
                  }}
                >
                  <span>Shift:</span>
                  <span>
                    {formatDate(startDate)} {formatTime(startDate)} &rarr;{" "}
                    {formatTime(endDate)}
                  </span>
                </div>

                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <span>Accepted:</span>
                  <span>{acceptedCount}</span>
                </div>

                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <span>Rejected:</span>
                  <span>{rejectedCount}</span>
                </div>

                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <span>Total:</span>
                  <span>{elements[page]?.length}</span>
                </div>

                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <span>Mod Score:</span>
                  <span>{modScore}</span>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "25px",
                }}
              >
                <Button
                  label="Save Review"
                  style={{
                    // backgroundColor: "#5555AA",
                    backgroundColor: "grey",
                    color: "white",
                    padding: "10px 20px",
                    border: "none",
                    borderRadius: "5px",
                    cursor: "pointer",
                    fontSize: "16px",
                  }}
                  // onClick={fetchReviewHistory}
                />
                <Button
                  label="Copy Score"
                  style={{
                    backgroundColor: "#5555AA",
                    color: "#fff",
                    padding: "10px 20px",
                    border: "none",
                    borderRadius: "5px",
                    cursor: "pointer",
                    fontSize: "16px",
                  }}
                  onClick={() => navigator.clipboard.writeText(modScore)}
                />
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="filters">
        <div className="refresh">
          <Button icon=" pi pi-replay" onClick={() => reset()} />
        </div>

        <PageNavigation />

        <div className="admin-filter">
          {ADMIN_MODS.includes(uid) && (
            <Button
              label="Review Mode"
              style={
                reviewMode
                  ? { marginBottom: "10px" }
                  : { backgroundColor: "#5555AA", marginBottom: "10px" }
              }
              onClick={() => {
                if (reviewMode) return;
                if (
                  uid !== adminFilter ||
                  adminFilter === "all" ||
                  !adminFilter
                ) {
                  setShowReviewConfig(true);
                }
              }}
            />
          )}
          <Dropdown
            options={admins}
            onChange={(e) => {
              setPage(0);
              setAdminFilter(e.value);
              setLastPost(null);
            }}
            value={adminFilter}
            showClear
            placeholder="All Admins"
          />

          <input
            placeholder="All Admins"
            style={{ marginTop: "10px", height: "28px" }}
            type="datetime-local"
            onChange={(e) => {
              setRange(e.target.value);
            }}
            value={range}
          />
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          gap: "10px",
          marginTop: "-45px",
        }}
      >
        <div className="action-filter">
          <label style={{ fontSize: "19px" }}>Action: </label>
          <Dropdown
            options={actionOptions}
            value={actionFilter}
            onChange={(e) => {
              setPage(0);
              setActionFilter(e.value);
              console.log("this is action filter:", e.value);
              setLastPost(null);
            }}
            placeholder="All"
            showClear={showClearIcon}
          />
        </div>
        <div className="action-filter">
          <label style={{ fontSize: "19px" }}>Gender: </label>
          <Dropdown
            options={genderOptions}
            value={genderFilter}
            onChange={(e) => {
              setPage(0);
              setGenderFilter(e.value);
              console.log("this is gender filter:", e.value);
              setLastPost(null);
            }}
            placeholder="All"
            showClear={showClearIcon}
          />
        </div>
        {actionFilter === "delete" && (
          <div className="action-filter" style={{ marginLeft: "5px" }}>
            <label style={{ fontSize: "19px" }}>Reason: </label>
            <Dropdown
              options={reasonOptions}
              value={reasonFilter}
              onChange={(e) => {
                setPage(0);
                setReasonFilter(e.value);
                console.log("this is reason filter:", e.value);
                setLastPost(null);
              }}
              placeholder="Reason"
              showClear={showClearIcon}
            />
          </div>
        )}
        <div style={{ marginLeft: "45px" }}>
          <input
            type="text"
            placeholder="Search by User ID"
            style={{
              height: "30px",
              width: "200px",
              borderRadius: "5px",
              border: "1px solid #ccc",
            }}
            onChange={(e) => {
              console.log("this is search by userID:", e.target.value);
              // initialFetch(e.target.value);
              setUserFilter(e.target.value);
            }}
            value={userFilter}
          />
        </div>
      </div>

      <div className="block posts">
        {isLoading ? <ProgressSpinner /> : <HistoryPosts />}
      </div>

      <PageNavigation />
    </div>
  );
}

export default HistoryComponent;
