// src/redux/globalSlice.js
import { createSlice } from '@reduxjs/toolkit';

const globalSlice = createSlice({
  name: 'global',
  initialState: {
    internetSpeed: 2, // Initialize
    delay: 4500
  },
  reducers: {
    setInternetSpeed: (state, action) => {
      state.internetSpeed = action.payload;
      switch(action.payload){
        case action.payload < 300:
            state.delay = 6000;
        case action.payload <= 400:
            state.delay = 4000;
        case action.payload > 400:
            state.delay = 3500;    
        } 
      console.log("setting internet speed", action.payload)
    },
  },
});

export const { setInternetSpeed } = globalSlice.actions;

export const internetSpeed = (state) => state?.global?.internetSpeed;
export const delay = (state) => state?.global?.delay;
// export const selectActivePost = (state) => state?.posts?.activePost;


export default globalSlice.reducer;
