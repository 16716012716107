import React, { useState } from 'react'
import { Card, CardActionArea, CardContent, CardMedia, Typography, Alert, Snackbar } from "@mui/material";
import { useDispatch } from 'react-redux';
import { setActivePost } from '../../../posts/postsSlice';

export default function PostCard({ id, post }) {

    const [open, setOpen] = useState(false);

    const {
        age_ai,
        age_req,
        age_url,
        birthday_ai,
        date,
        liveliness,
        priority,
        st_dev,
        uid,
        waiting_moderation
    } = post;

    const handleCopy = () => {
        navigator.clipboard.writeText(uid);
        setOpen(true);
    }

    const handleClose = () => {
        setOpen(false);
    }

    const dispatch = useDispatch();
    return (
        <>
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={open}
                autoHideDuration={2500}
                onClose={handleClose}
                message="This Snackbar will be dismissed in 5 seconds."
            >
                <Alert severity="success" sx={{ width: '100%' }}>
                    Copied User ID
                </Alert>
            </Snackbar>
            <Card sx={{ maxWidth: 200, backgroundColor: 'transparent', marginLeft: '35px' }}>
                <CardActionArea >
                    <CardMedia
                        component="img"
                        height="300"
                        image={age_url}
                        alt="Person"
                        sx={{ objectFit: 'cover' }}
                        onClick={() => {
                            console.log("setting active post to", id)
                            dispatch(setActivePost(id))
                        }}
                    />
                    <CardContent sx={{ backgroundColor: 'rgba(250, 250, 250, 0.5)', padding: '5px' }}>
                        <Typography
                            variant="subtitle2"
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'flex-start',
                                padding: '0px',
                                margin: '0px',
                                cursor: 'pointer',
                                '&:hover': {
                                    color: 'grey',
                                    textDecoration: 'underline'
                                }
                            }}
                            onClick={() => handleCopy(uid)}
                        >
                            {uid}
                        </Typography>
                    </CardContent>
                </CardActionArea>
            </Card >
        </>
    )
}
