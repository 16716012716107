import { sendNotification } from "../../../../app/firebase";

interface RestoreNotificationProps {
  fcm_token: string;
}

export function sendRestoreNotification({
  fcm_token,
}: RestoreNotificationProps) {
  sendNotification({ registrationToken: fcm_token, restore: true })
    .then()
    .catch((error) => console.log("Restore notification error", error));
}
