import { Grid } from '@mui/material'
import React, { useMemo } from 'react'
import AgePost from '../AgePost'

export default function Grid5({ posts }) {

    const postsItems = useMemo(() => posts?.map((post, index) => {
        return (
            <Grid item sx={{ flexBasis: '20%' }} key={index}>
                <AgePost id={index} post={post} />
            </Grid>
        )
    }), [posts])

    return (
        <Grid container className="ageVerification" rowSpacing={2} width={'85%'}>
            {postsItems}
        </Grid>
    )
}
