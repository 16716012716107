import { db, firestore } from "../../../../app/firebase";

interface RestorePostProps {
  collection: string;
  userID: string;
  moderatorID: string;
  post: any;
}

export function restorePost({
  collection,
  userID,
  post,
  moderatorID,
}: RestorePostProps) {
  db.collection(collection)
    .doc(userID)
    .set({
      ...post,
      img_state: 5,
      finish_processing: true,
      post_seen_by: firestore.FieldValue.arrayUnion(moderatorID),
      seen_mod_date: firestore.FieldValue.serverTimestamp(),
      post_seen_mod: true,
      datePost: firestore.FieldValue.serverTimestamp(),
    })
    .then(() => {});
}
