import { Box, Button, CircularProgress, Snackbar, Alert } from '@mui/material'
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import { useDispatch, useSelector } from 'react-redux';
import { changePage, selectPage } from '../../../posts/postsSlice';
import { useCallback, useEffect, useState } from 'react';
import { get_counter_mod } from '../../../../app/firebase';
import HistoryIcon from '@mui/icons-material/History';
import { Dropdown } from 'primereact/dropdown';


export default function PageNavigation({ isHistory, setIsHistory, postsLength, fetchNextPage, loading, setLoading, isLast, adminFilter, setAdminFilter, admins }) {
    const [counter, setCounter] = useState(null);
    const [showAlert, setShowAlert] = useState(false);
    

    let page = useSelector(selectPage);
    const dispatch = useDispatch();
    const PagePaper = styled(Paper)(({ theme }) => ({
        width: 20,
        height: 20,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: theme.spacing(2),
        ...theme.typography.body2,
        textAlign: 'center',
    }));

    const getCountOfData = useCallback(async () => {
        let count = await get_counter_mod({ app: "addchat", section: "age" });
        if (count?.data?.status === 1) {
            setCounter(count?.data?.counter);
        } else {
            console.error("failed to fetch counter")
        }
        // console.log("called counter")
    }, [postsLength]);

    useEffect(() => {
        getCountOfData();
    }, [getCountOfData]);

    const handleClose = () => {
        setShowAlert(false);
    }

    return (
        <>
            <Snackbar
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center'
                }}
                open={showAlert}
                autoHideDuration={2500}
                onClose={handleClose}
            >
                <Alert severity="warning" sx={{ width: '100%' }}>
                    This is First Page!
                </Alert>
            </Snackbar>
            <nav style={{ paddingTop: 0, borderBottom: '1px solid lightgray', marginBottom: '5px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <PagePaper square={false} sx={{ marginLeft: '50px' }}>
                    {
                        counter === null ?
                            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                                <CircularProgress size={20} />
                            </Box>
                            :
                            counter
                    }
                </PagePaper>

                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '3px' }}>
                    <Button sx={{
                        backgroundColor: 'rgba(190, 190, 190, 0.8)',
                        color: 'white',
                        '&:hover': {
                            backgroundColor: 'grey',
                            textDecoration: 'bold'
                        }
                    }}
                        startIcon={<ArrowBackOutlinedIcon />}
                        onClick={() => {
                            if (page > 1) {
                                setLoading(true);
                                dispatch(changePage(page - 1));
                            } else {
                                setShowAlert(true);
                            }
                        }}>
                        Back</Button>

                    <PagePaper square={false}>{page}</PagePaper>

                    <Button sx={{
                        backgroundColor: 'rgba(190, 190, 190, 0.8)',
                        color: 'white',
                        '&:hover': {
                            backgroundColor: 'grey',
                            textDecoration: 'bold'
                        }
                    }}
                        endIcon={<ArrowForwardOutlinedIcon />}
                        onClick={() => {
                            if (loading) return; //to prevent spamming the button
                            if (!isLast) {
                                setLoading(true);
                                dispatch(changePage(page + 1))
                            }
                            else fetchNextPage()
                        }}>
                        Next
                    </Button>
                </div>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap:'5px' }}>
                    {isHistory &&
                        <Dropdown
                            options={admins}
                            onChange={(e) => {
                                setAdminFilter(e.value);

                            }}
                            value={adminFilter}
                            showClear
                            placeholder="All Admins"
                        />}
                        <PagePaper onClick={() => setIsHistory(prev => !prev)} square={false} sx={{ display: 'flex', height: '40px', width: '40px', alignItems: 'center', justifyContent: 'center', cursor: 'pointer', backgroundColor: isHistory ? 'grey' : '', color: isHistory ? 'white' : '', '&:hover': { backgroundColor: 'grey', color: 'white' } }}>
                        <HistoryIcon size={100} />
                    </PagePaper>
                </div>
            </nav>
        </>
    )
}
