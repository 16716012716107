const AnimatedDots = () => {
  return (
    <span style={{ display: 'inline-block', marginLeft: '0.2em' }}>
      {/* Inline CSS for the animated dots */}
      <style>
        {`
          @keyframes blink {
            0%, 80%, 100% { opacity: 0; }
            40% { opacity: 1; }
          }
          .dot {
            display: inline-block;
            width: 0.5em;
            height: 0.5em;
            margin-right: 0.2em;
            background-color: currentColor; /* Inherits text color */
            border-radius: 50%;
            opacity: 0;
            animation: blink 1.4s infinite both;
          }
          .dot:nth-child(1) {
            animation-delay: 0s;
          }
          .dot:nth-child(2) {
            animation-delay: 0.2s;
          }
          .dot:nth-child(3) {
            animation-delay: 0.4s;
          }
          /* Accessibility: Respect user's reduced motion preference */
          @media (prefers-reduced-motion: reduce) {
            .dot {
              animation: none;
              opacity: 1; /* Show all dots without animation */
            }
          }
        `}
      </style>
      {/* Three dots for the animated ellipsis */}
      <span className="dot"></span>
      <span className="dot"></span>
      <span className="dot"></span>
    </span>
  );
};

export default AnimatedDots;
