export function ControlButton(props) {
  return (
    <div className="control" onClick={props.onClick} style={props.style}>
      <button className={props.activeKey === props.letter ? "clicked" : ""}>
        {props.icon}
      </button>

      <div className="letter"> {props.letter} </div>
      <div className="description"> {props.description} </div>
    </div>
  );
}
