import { Box, Button, TextField, Snackbar, Alert, Tooltip } from '@mui/material';
import TableDetails from './TableDetails';
import { age_verif_mod } from '../../../../app/firebase';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { changePage, removePost2, selectPage, selectPosts, setActivePost, setFetchNext, setPosts } from '../../../posts/postsSlice';
import { convertSecondsTo, ageFlag } from './utils';
import dayjs from 'dayjs';

export default function ActivePost({ id, post }) {

    const {
        age_ai,
        age_req,
        age_url,
        birthday_ai,
        date,
        liveliness,
        priority,
        st_dev,
        uid,
        waiting_moderation,
        mod_res,
        mod_uid,
        mod_date
    } = post;

    let posts = useSelector(selectPosts);
    let loadedPage = useSelector(selectPage);

    // const [comment, setComment] = useState("");
    const [showComment, setShowComment] = useState({
        status: false,
        action: "BAN"
    });
    const [openSuccess, setOpenSuccess] = useState(false);
    const [openError, setOpenError] = useState(false);
    const [openErrorComment, setOpenErrorComment] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [tooltipOpen, setTooltipOpen] = useState(false);


    const dispatch = useDispatch();

    const buttonStyle = {
        backgroundColor: 'white',
        height: '70px',
        width: '150px',
        fontSize: "20px",
        fontWeight: '400',
        borderRadius: "40px",
        color: 'black',
        border: '1px solid lightgrey',
        transition: 'all 0.45s',
        '&:hover': {
            backgroundColor: 'grey',
            color: 'white'
        },
        display: 'flex',
        flexDirection: 'column',  // Arrange items in a column
        justifyContent: 'center', // Center items vertically
        alignItems: 'center',     // Center items horizontally
        textAlign: 'center'
    }

    const handleControls = async (res, comment) => {
        console.log(`{
            res: ${res},
            uid: ${uid},
            comment: ${comment}
            }`);

        age_verif_mod({ res, uid, comment })
            .then((result) => {
                if (result?.data?.status !== 1) {
                    // setOpenError(true);
                    // setErrorMessage(result?.data?.message);
                    console.log(result?.data?.message);
                    return;
                }
                console.log(result?.data?.message);
                // setSuccessMessage(result?.data?.message);
                // setOpenSuccess(true);
            })
            .catch((error) => {
                console.log(error?.message);
                // setOpenError(true);
                // setErrorMessage(error.message || 'An error occurred');
            });

        dispatch(removePost2({
            id
        }));
        if (posts[loadedPage].length === 1) {
            dispatch(setFetchNext(true));
        } // if the only post in page we move to next page
        else if (id === posts[loadedPage].length - 1) dispatch(setActivePost(id - 1)) // if last post in the page but there is posts before it we should stay in the page
    }

    const handleClick = (action) => {
        let res = action === "BAN" ? 3 : action === "SUPPORT" ? 4 : -1;
        if (res === -1) {
            return alert("Contact Support because res integer is unspported");
        }

        const userComment = prompt(`Please enter a comment to ${action} this user:`);

        if (userComment !== null) {
            handleControls(res, userComment)
        } else {
            console.log('Ban action cancelled');
            // setOpenErrorComment(true)
        }
    }

    const handleKeyDown = (e) => {
        if (e.target.tagName === 'INPUT' || e.target.tagName === 'TEXTAREA' || e.target instanceof HTMLInputElement || e.target instanceof HTMLTextAreaElement) {
            return //The key was pressed inside an input or textarea.
        }
        switch (e.key) {
            case 'j':
                handleControls(1);
                break;
            case 'f':
                handleControls(2)
                break;
            case 'i':
                handleClick("BAN");
                break;
            case 's':
                handleClick("SUPPORT");
                break;
        }
    }

    useEffect(() => {
        document.addEventListener('keydown', handleKeyDown)
        return () => {
            document.removeEventListener('keydown', handleKeyDown)
        }
    })

    const getAgeDiff = useCallback(() => {
        return ageFlag(age_req, age_ai);
    }, [age_req, age_ai]);

    const ageDiff = getAgeDiff();

    return (
        <div className='full-screen'>
            <div className='active-post'>
                <div className='details'>
                    <div style={{
                        position: 'fixed',
                        top: '2%',
                        left: '2%'
                    }}>
                        <Button variant="text" sx={buttonStyle} style={{
                            height: '40px',
                            width: '100px',
                            fontSize: "18px",
                            fontWeight: '400',
                        }}
                            onClick={() => {
                                dispatch(setActivePost(-1));
                            }}>
                            Close
                        </Button>
                    </div>
                    <Tooltip
                        title="Click to copy"
                        arrow
                        open={tooltipOpen}
                        onOpen={() => setTooltipOpen(true)}
                        onClose={() => setTooltipOpen(false)}
                    >
                        <Box
                            sx={{
                                position: 'fixed',
                                bottom: '4%',
                                right: '4%',
                                color: 'grey',
                                cursor: 'pointer',
                                '&:hover': {
                                    color: 'black',
                                    textDecoration: 'underline',
                                },
                            }}
                            onClick={() => {
                                if (mod_uid) {
                                    navigator.clipboard.writeText(mod_uid)
                                    setTooltipOpen(false);
                                }
                            }}
                        >
                            {mod_uid}
                        </Box>
                    </Tooltip>
                    <Box
                        sx={{
                            position: 'fixed',
                            bottom: '8%',
                            right: '4%',
                            color: 'grey',
                            cursor: 'pointer',
                            '&:hover': {
                                color: 'black',
                                textDecoration: 'underline',
                            },
                        }}
                    >
                        Date {mod_date ? 'Moderated' : 'Submitted'}: {dayjs.unix(mod_date?.seconds || date.seconds).fromNow()}
                    </Box>
                    <div style={{ width: '250px' }}>
                    </div>
                    <div className='active-post-img' style={{
                        border: mod_res ? mod_res == 1 ? '10px solid lightgreen' : '10px solid lightcoral' : '',
                        borderRadius: '20px',
                    }}>
                        <img src={age_url} />
                        <div style={{
                            position: 'fixed',
                            top: '67.5vh',
                            backgroundColor: 'white',
                            width: '155px',
                            height: '48px',
                            fontSize: '30px',
                            padding: '5px',
                            // textAlign: 'center',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            gap:'5px',
                            borderRadius: '20px',
                            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.15)',
                        }}>
                            <span>{age_req > 17 ? '🟡 ' : '🔴 '}</span>
                            <span style={{ fontWeight: '500' }}>{age_req}</span>
                            <span>({ageDiff[1] > 0 ? `+${ageDiff[1]}` : ageDiff[1]})</span></div>
                    </div>
                    <div style={{ width: '250px' }}>
                    </div>
                </div>
                <TableDetails age_ai={age_ai} age_req={age_req} liveliness={liveliness} mod_res={mod_res} />
                <div className='active-post-controls'>
                    <Button variant="text" sx={buttonStyle} onClick={() => handleControls(1)}>
                        <Box display="flex" alignItems="center">
                            {/* <DoneOutlineOutlinedIcon sx={{ fontSize: 15, marginRight: 1 }} /> */}
                            <Box>Accept</Box>
                        </Box>
                        <Box fontSize="18px" color="grey" >J</Box>
                    </Button>
                    <Button variant="text" sx={buttonStyle} onClick={() => handleControls(2)}>
                        <Box display="flex" alignItems="center">
                            {/* <CloseOutlinedIcon sx={{ fontSize: 15, marginRight: 1 }} /> */}
                            <Box>Reject</Box>
                        </Box>
                        <Box fontSize="18px" color="grey" >F</Box>
                    </Button>
                    <Button variant="text" sx={buttonStyle} onClick={() => {
                        handleClick("BAN");
                    }}>
                        <Box display="flex" alignItems="center">
                            {/* <MobileOffOutlinedIcon sx={{ fontSize: 15, marginRight: 1 }} /> */}
                            <Box>Ban</Box>
                        </Box>
                        <Box fontSize="18px" color="grey">I</Box>
                    </Button>
                    <Button variant="text" sx={buttonStyle} onClick={() => {
                        handleClick("SUPPORT");
                    }}>
                        <Box display="flex" alignItems="center">
                            {/* <DeleteOutlineOutlinedIcon sx={{ fontSize: 15, marginRight: 1 }} /> */}
                            <Box>Support</Box>
                        </Box>
                        <Box fontSize="18px" color="grey" >S</Box>
                    </Button>
                </div>
            </div>
            <Snackbar
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                }}
                open={openErrorComment}
                autoHideDuration={2500}
                onClose={() => setOpenErrorComment(false)}
            >
                <Alert severity="error" sx={{ width: '100%' }}>
                    "Please enter a comment to be able to {showComment.action}"
                </Alert>
            </Snackbar>
            <Snackbar
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                }}
                open={openError}
                autoHideDuration={2500}
                onClose={() => setOpenError(false)}
            >
                <Alert severity="error" sx={{ width: '100%' }}>
                    "Error: {errorMessage}"
                </Alert>
            </Snackbar>
            <Snackbar
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                }}
                open={openSuccess}
                autoHideDuration={300}
                onClose={() => {
                    setOpenSuccess(false);
                    let updatedPosts = [
                        ...posts[loadedPage],
                    ]
                    updatedPosts.splice(id, 1)
                    dispatch(setPosts({
                        posts: updatedPosts,
                        page: loadedPage
                    }))
                }}
            >
                <Alert severity="success" sx={{ width: '100%' }}>
                    "Success! {successMessage}"
                </Alert>
            </Snackbar>
        </div>
    )
}

