export function swipingControlButton(props) {
  // Apply the 'disabled' class based on is_enable prop
  console.log(props.is_enable,"props.is_enable");
  const buttonClass = `bottom-btn ${props.is_enable && 'disabled'  }`;

  return (
    <button className={buttonClass} onClick={props.onClick} >
      {props.description}
      <p>{props.letter}</p>
    </button>
  );
}
