import { db, firestore } from "../../../../app/firebase";

interface Props {
  imageID: string;
  userID: string;
  reason_report: string;
  reason_report_int: string;
}

export function changeDeleteReasonHistory({
  imageID,
  userID,
  reason_report,
  reason_report_int,
}: Props) {
  db.collection("history")
    .doc(imageID)
    .set(
      {
        action: "delete",
        mod_uid: userID,
        edit_counter: firestore.FieldValue.increment(1),
        reason: reason_report,
        reason_int: reason_report_int,
        location: "history",
        date: firestore.FieldValue.serverTimestamp(),
      },
      { merge: true }
    );
}
