import { createSlice } from "@reduxjs/toolkit";

export const postsSlice = createSlice({
    name: "posts",

    initialState: {
        pressedKey: 0,
        all: [],
        filter: [],
        reported: [],
        unread: [],
        verification: [],
        loading: true,
        activePost: -1,
        page: 1,
        lastSeen: false,
        chatHistory: [],
        firstFetch: false,
    },
    reducers: {
        setPosts: (state, action) => {
            // console.log("state, action",state, action);
            // if(action.payload.firstFetch) state.all = []
            if (action.payload.unread) {
              state.unread[action.payload.page] = action.payload.posts;
            } else if (action.payload.reported) {
              state.reported[action.payload.page] = action.payload.posts;
            } else if (action.payload.page) {
              state.filter[action.payload.page] = action.payload.posts;
            } else if (action.payload.chatHistory) {
              state.chatHistory[action.payload.page] = action.payload.posts;
            } else {
              state.all[action.payload.page] = action.payload.posts;
            }
            state.loading = false;
          },
        setActivePost: (state, action) => {
            if (action.payload >= -1) state.activePost = action.payload;
        },
        removePost: (state, action) => {
            let id = action.payload.id;

            if (state.reported[state.page])
                state.reported[state.page].forEach((post, i) => {
                    if (post.id === action.payload.id)
                        state.reported[state.page].splice(i, 1);
                });

            if (state.all[state.page])
                state.all[state.page].forEach((post, i) => {
                    if (post.id === action.payload.id) state.all[state.page].splice(i, 1);
                });

            if (state.unread[state.page])
                state.unread[state.page].forEach((post, i) => {
                    if (post.id === action.payload.id)
                        state.unread[state.page].splice(i, 1);
                });
            if (state.filter[state.page])
                state.filter[state.page].forEach((post, i) => {
                    if (post.id === action.payload.id)
                        state.filter[state.page].splice(i, 1)
                })
        },
        setPostUsername: (state, action) => {


            if (action.payload.unread) {
                state.unread[state.page][state.activePost].username =
                    action.payload.username;
            } else if (action.payload.reported) {
                state.reported[state.page][state.activePost].username =
                    action.payload.username;
            } else {
                state.all[state.page][state.activePost].username =
                    action.payload.username;
            }

        },
        setPostText: (state, action) => {
            if (action.payload.unread) {
                state.unread[state.page][state.activePost].postText =
                    action.payload.postText;
            } else if (action.payload.reported) {
                state.reported[state.page][state.activePost].postText =
                    action.payload.postText;
            } else {
                state.all[state.page][state.activePost].postText =
                    action.payload.postText;
            }
        },
        nextPost: (state) => {
            state.activePost++;
        },
        changePage: (state, action) => {
            state.page = action.payload;
        },
    },
});

export const {
    keyDown,
    updatePosts,
    setActivePost,
    nextPost,
    setPosts,
    removePost,
    changePage,
    setLastSeen,
    setPostUsername,
    setPostText,
} = postsSlice.actions;

export const selectPosts = (state) => state.posts.all;
export const selectFilterPost = (state) => state.posts.filter;
export const selectReportedPosts = (state) => state.posts.reported;
export const selectUnreadPosts = (state) => state.posts.unread;
export const selectChatHistory = (state) => state.posts.chatHistory;

export const selectPage = (state) => state.posts.page;
export const selectLastSeen = (state) => state.posts.lastSeen;

export const selectVerificationPosts = (state) => state?.posts?.verification;

export const selectPostsLoading = (state) => state?.posts?.loading;
export const selectActivePost = (state) => state?.posts?.activePost;

export default postsSlice.reducer;
