import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { InputText } from "primereact/inputtext";
import { useState } from "react";
import { sendNotification } from "../../app/firebase";
import "./configuration.css";
import { Fieldset } from "primereact/fieldset";

import { TabView, TabPanel } from "primereact/tabview";
import { MessagesPreview } from "./MessagesPreview";

export function DevelopmentConfiguration() {
  const [fcmToken, setFcmToken] = useState("");

  function handleSendNotification() {
    alert(fcmToken);
    sendNotification({ keep: true, registrationToken: fcmToken });
  }

  return (
    <div className="developer">
      <TabView>
        <TabPanel header="Messages">
          <MessagesPreview />
        </TabPanel>

        <TabPanel header="Notification">
          <div className="p-field">
            <InputText
              onChange={(e) => setFcmToken(e.target.value)}
              value={fcmToken}
            />
          </div>

          <div className="p-field">
            <Button
              onClick={handleSendNotification}
              label="Send Notification"
            />
          </div>
        </TabPanel>

        <TabPanel header="Info">
          <div> ID: D6qtfRnvACR31NRcOnKucWRWEVH (2) </div>
          <div>
            FCM token:
            fXpBHmJ9rEeFv2G_LGFoDB:fXpBHmJ9rEeFv2G_LGFoDB:APA91bHX3U9yv59fSB1a65vqt4iAknNZkfMg20vZOmz6IsZWdqOds5jqoTw8kTBtV_atioKnlAmR8o47K4-w-JTvt3KVBpiDmpEBugjs4G33blz_8wOSjqgAQiJ6jloswt_SSr4FnQv9
          </div>
        </TabPanel>
      </TabView>
    </div>
  );
}
