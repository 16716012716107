import { sendNotification } from "../../../../app/firebase";

interface Props {
  fcm_token: string;
}

export function sendDeleteNotification({ fcm_token }: Props) {
  sendNotification({ registrationToken: fcm_token, delete: true })
    .then()
    .catch((error) => console.log("Delete notification error", error));
}
