import { db, firestore } from "../../../app/firebase";
import { v4 as uuidv4 } from "uuid";

export default function sendDeleteMessage({ username, uid, emoji, reason }) {
  let admin_uid = uuidv4();

  let usersData = {
    useraid: admin_uid,
    userbid: uid,

    userausername: "Addchat Team",
    userbusername: username,

    emojia: "⚠️",
    emojib: emoji,
  };

  if (admin_uid < uid) {
    usersData = {
      useraid: uid,
      userbid: admin_uid,

      userausername: username,
      userbusername: "Addchat Team",

      emojia: emoji,
      emojib: "⚠️",
    };
  }

  let message = {
    ...usersData,
    message: "Your post was deleted!",
    type: "text",
    status: "Sent",
    senderID: admin_uid,
    date: firestore.FieldValue.serverTimestamp(),
    // date: Date.now() / 1000,
  };

  db.collection("recent_chat")
    .doc(uid)
    .collection("recent_list")
    .doc(admin_uid > uid ? admin_uid + uid : uid + admin_uid)
    .set(message, { merge: true });

  let chat = {
    userID: admin_uid,
    text: "Your post was deleted because you didn’t respect our rules. If you continue breaking them, we will ban your account.",
    status: "Sent",
    type: "text",
    date: firestore.FieldValue.serverTimestamp(),
    // date: Date.now() / 1000,
  };

  if (reason?.reason_report) {
    chat.text = deleteMessage[reason?.reason_report_int ?? 0];
  }

  const chatName = admin_uid > uid ? admin_uid + uid : uid + admin_uid;

  db.collection("chat").doc(chatName).collection("discussion").add(chat);
}

const deleteMessage = {
  // guidelines
  0: `Hey, we removed your last post because it didn’t follow our rules. 
Here’s a quick reminder:

 ⁃ Show your Face
 ⁃ Don’t use someone else’s photo
 ⁃ No shirtless pics inside

Thanks for understanding
Addchat Team`,

  // Nudity:
  1: " Your last post was removed because our moderators noticed that the Image you posted was inappropriate for Addchat. Nudity and Partial Nudity should not be posted publicly on Addchat. *** No nudity, no Suggestive, no Shirtless indoor (except beach photos), and no Underwear pictures are allowed *** Please follow our guidelines to keep Addchat safe for everyone.",

  // Underage:
  2: "Your last post was removed because our moderators found it inappropriate. Addchat is currently for people 18 and over. Which means having your age in your Username, Caption ( text ), or looking Underage will lead to post removal. For your own safety, we advise you to deactivate your account.",

  // Selling:
  3: "Your last post was removed because our moderators found it inappropriate for Addchat. Selling and self-promotion are prohibited on Addchat. We aim to keep our users safe. Please stop promoting on Addchat (Publicly and in chat). Thank you for your cooperation. Continuing to break our rules will lead to a permanent ban.",

  // SuggestiveSexual:
  4: "Your last post was removed because our moderators found it inappropriate for Addchat. Suggestive / Sexual meaning that the image or text posted, isn’t appropriate for Addchat. Image, Caption (text) and Username should follow our guidelines. Do not be sexual or suggestive publicly on Addchat. Thank you for your cooperation.",

  // Scam:
  5: "Your last post was removed because our moderators found it inappropriate for Addchat. Scamming our community will not be tolerated. Please stop promoting on Addchat ( Publicly and in chat ). Thank you for your comprehension. Continuing to break our rules will lead to a permanent ban.",

  // Illegal:
  6: "Your last post was removed because our moderators found it inappropriate for Addchat. Illegal activities were noticed by our moderators. If deemed necessary, law enforcement will be contacted to resolve this issue. Stop posting on Addchat. Thank you for your comprehension. Continuing to break our rules will lead to a permanent ban.",
};
