import { db, firestore } from "../../../../app/firebase";
import { parseUrl } from "../../../../helpers/helpers";

export function writeRestoreHistory({ imageID, modID }) {
  console.log("imageID", imageID);
  let parsedIMG = parseUrl(imageID);
  db.collection("history")
    .doc(imageID || parsedIMG)
    .set(
      {
        action: "keep",
        date: firestore.FieldValue.serverTimestamp(),
        mod_uid: modID,
        edit_counter: firestore.FieldValue.increment(1),
        reason: "mistake",
        location: "history",
      },
      { merge: true }
    ).then(() => {
      console.log("Successful write restore to history:", parsedIMG || imageID);
    })
    .catch((error) => {
      console.error("Failed to write restore to history:", error);
    });
}
