import SearchUser from '../../../search/SearchUser'

export default function SearchUserPopup({ showPopup, setShowPopup }) {
    return (
        <>
            {
                showPopup.show && showPopup.ID &&
                <div style={showPopup?.show && showPopup?.ID ? { backgroundColor: "rgba(0, 0, 0, 0.4)", width: "100vw", height: "100vh", zIndex: 1000, position: "fixed", top: 0, left: 0 } : {}}>
                    <div className="chatpopup_container" style={{
                        position: 'fixed', // Or 'absolute', if you prefer
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)', // Centers the div
                        width: '80vw',
                        border: '2px solid grey',
                        borderRadius: '10px',
                        backgroundColor: 'white',
                        zIndex: 1000,
                        // Ensure content is scrollable if it overflows
                        height: '90vh',
                        overflowY: 'scroll'
                    }}>
                        <button className="close" style={{ position: "sticky" }} onClick={() =>
                            setShowPopup({ show: false, ID: undefined })
                        }>
                            Close
                        </button>
                        <SearchUser deletePosts={false} isDevelopment={false} userID={showPopup.ID ? showPopup.ID : undefined} />
                    </div>
                </div>
            }
        </>
    )
}
