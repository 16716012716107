export const firebaseConfig = {
  apiKey: "AIzaSyCQTKtqpY2omQ_KfioQyFar1Wvpi4gLy30",
  authDomain: "addchat-9e72c.firebaseapp.com",
  databaseURL: "https://addchat-9e72c.firebaseio.com",
  projectId: "addchat-9e72c",
  storageBucket: "addchat-9e72c.appspot.com",
  messagingSenderId: "644536032495",
  appId: "1:644536032495:web:9f16a3af808e11ea5e0f1a",
  measurementId: "G-VKC30L9XHR"
};

export const reCaptchaSiteKey = {
  siteKey: "6LeJnkYfAAAAANh7iAIZ_oBNba5Ogj_mXFGhK2az"
}
  // new_mod@addchatapp.com
  // FKLE382946SKJF
  // j6d37K69EOh4MHD3usBaVmMPv4m2

// dev_upwork@addchatapp.com
// LSDRGN2451jkaKJ320


  // https://onlyfans.com/blossomlolz