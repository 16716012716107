
import { db } from "../../../app/firebase";
import { firestore } from "../../../app/firebase";
import { parseUrl, removeUndefined2 } from "../../../helpers/helpers";

export function deleteHistory({
  post,
  user = { uid: "none" },
  reason,
  location = "not-specified",
  action = "delete",
  verification_url,
  profile_pic_url
}) {
  const imageID = parseUrl(post?.imageUrlPost);

  if(reason?.reason_report === 'delete'){
    return alert("reason can not be delete");
  }
  return db.collection("history")
    .doc(imageID ?? user.uid)
    .set(
      {
        post_data: removeUndefined2(post),
        mods: firestore.FieldValue.arrayUnion(user.uid),
        action,
        edit_counter: firestore.FieldValue.increment(1),
        mod_uid: user.uid,
        ...(!!reason
          ? {
              reason: reason.reason_report,
              reason_int: reason.reason_report_int,
            }
          : {}),
        location,
        verification_url,
        profile_pic_url,
        date: firestore.FieldValue.serverTimestamp(),
        imageID: imageID,
      },
      { merge: true }
    );
}
