export function parseUrl(url: string) {
  if(!url){
    return null;
  }
  let start = url.search("posts%2F") + "posts%2F".length;
  if (start === -1) return url;
  let end = url.search(/\.jpg/);
  if (end === -1) end = url.length;

  let parsed = url.slice(start, end);

  return parsed;
}

export function removeUndefined(obj: { response_time: any }) {
  let newObj = { ...obj };
  if (obj?.response_time === undefined) {
    delete newObj?.response_time;
  }
  return newObj;
}

export function removeUndefined2(obj: { [key: string]: any }) {
  return Object.fromEntries(Object.entries(obj).filter(([_, v]) => v !== undefined));
}

export function removeUndefined3(obj: { [key: string]: any }): any {
  if (obj === undefined || obj === null) {
    return null;
  }

  if (Array.isArray(obj)) {
    return obj.filter((item) => item !== undefined).map(removeUndefined3);
  }

  if (typeof obj === 'object') {
    for (const key in obj) {
      if (obj[key] && typeof obj[key] === 'object') {
        obj[key] = removeUndefined3(obj[key]);
      } else if (obj[key] === undefined) {
        delete obj[key];
      }
    }
  }

  return obj;
}




export function isStableVersion(version: string) {
  const [major, minor, patch] = version.split('.').map(Number);
  return major >= 2;
}
