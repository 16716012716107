import { useState, useEffect, useMemo, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import dayjs from "dayjs";

import {
    firebaseDeletePost,
    db,
    firestore,
    logAnalytics,
    ban_user_mod,
    get_counter_mod,
    view_verif_img,
    age_verif_mod
} from "../../app/firebase";
import 'firebase/firestore';
import firebase from 'firebase';

import { getPosts, deleteNotification, keepNotification } from "./model";
import { chat_report_mod } from '../../app/firebase'
import { res_chat_report_swiping } from '../../app/firebase'
import sendDeleteMessage from "./functions/sendDeleteMessage";

import { selectUser } from "../auth/userSlice";
import {
    nextPost,
    selectPosts,
    setActivePost,
    selectActivePost,
    selectPostsLoading,
    selectReportedPosts,
    selectPage,
    changePage,
    removePost,
    setPostUsername,
    selectUnreadPosts,
    selectFilterPost,
    selectChatHistory,
} from "./postsSlice";

import Post from "./Post";
import { deleteHistory } from "./functions/deleteHistory";
import { Button } from "primereact/button";
import { createTest } from "./functions/createTest";
import { Controls } from "./Controls";
import { keepHistory } from "./functions/Keep.functions";
import { ProgressSpinner } from "primereact/progressspinner";
import { Dropdown } from 'primereact/dropdown';
import { SiElasticsearch } from "react-icons/si";
import SearchUser from "../search/SearchUser";
import { clearPosts } from "../posts/postsSlice";
import { Alert, Snackbar } from "@mui/material";


function Chat(props) {
    let { uid } = useSelector(selectUser);
    const { userIdURLParam } = useParams();
    let userIdParam = userIdURLParam ? userIdURLParam : props?.userID ? props?.userID : false;

    const [key, setKey] = useState("");
    const [banPrompt, setBanPrompt] = useState(false);
    const [textPrompt, setTextPrompt] = useState(false);
    const [reasonPrompt, setReasonPrompt] = useState(false);
    const [verifImg, setVerifyImg] = useState("");
    const [isPictureFullscreen, setIsPictureFullscreen] = useState(false);
    const [bio, setBio] = useState("");
    const [lastSeen, setLastSeen] = useState({});
    const [roomIdData, setRoomIdData] = useState({});
    const [roomData, setRoomData] = useState({});
    const [userId, setUserId] = useState("");
    const [Id, setId] = useState("");
    const [Id2, setId2] = useState("");
    let [k, setK] = useState(0);
    let [n, setN] = useState(0);
    const [totalProfileCount, setTotalProfileCount] = useState(undefined)
    const [profileInfo, setProfileInfo] = useState()
    const [page, setPage] = useState(0);
    const [pageNumber, setPageNumber] = useState(1);
    const [showDropdown, setShowDropdown] = useState(userIdParam ? true : false);
    const [admins, setAdmins] = useState([{ label: uid, value: uid }]);
    const [adminFilter, setAdminFilter] = useState(null);
    const [lastPost, setLastPost] = useState();
    const [range, setRange] = useState('');
    const [switchS, setSwitchS] = useState(true);
    const currentTimestamp = firebase.firestore.Timestamp.now();
    const [isLoading, setIsLoading] = useState(false);
    const [countResponse, setCountResponse] = useState("-");
    const [elements, setElements] = useState([]);
    const limit = 10;

    const [showPopup, setShowPopup] = useState({ show: false, ID: undefined });
    const [showSuccess, setShowSuccess] = useState(false);

    const [reportedFilter, setReportedfilter] = useState(userIdParam ? userIdParam : "");

    const [actionFilter, setActionFilter] = useState(0);
    const actionOptions = [
        { label: 'All', value: 0 },
        { label: 'Keep', value: 1 },
        { label: 'Delete', value: 2 },
        { label: 'Ban', value: 3 },
    ];
    const showClearIcon = actionFilter !== '';


    let [postsCategory, setPostsCategory] = useState([]);
    const right = useRef(null);
    const left = useRef(null);
    var selected_mod_uid = adminFilter;
    const location1 = useLocation();
    const { fromSearch, fromSearchID } = location1.state || {};
    const { pathname } = location1;
    const isChatPage = pathname === '/swiping/chat'
    // console.log("isChatPage", isChatPage);
    // let k = 0
    //-----------------------------------------------------------------------------------

    const location =
        // props.reported
        // ? "all_reported"
        // : props?.unread
        //     ? "new_posts"
        //     :
        "all_posts";


    function handleDelete(reason) {
        deletePost(reason);
    };

    let user = useSelector(selectUser);


    let allPosts = useSelector(selectPosts);
    let filterPost = useSelector(selectFilterPost);
    let chatHistoryPost = useSelector(selectChatHistory)

    // let unreadPosts = useSelector(selectUnreadPosts);
    // let reportedPosts = useSelector(selectReportedPosts);

    let loadedPage = useSelector(selectPage);
    postsCategory =
        props.filter ? filterPost :
            props.chatHistory ? chatHistoryPost : allPosts;

    let posts = useMemo(() => {
        console.log("these are the posts", postsCategory[loadedPage] || []);
        return postsCategory[loadedPage] || [];
    }, [loadedPage, postsCategory]);

    // console.log("postsCategory", chatHistoryPost, allPosts);
    // console.log("postsCategory", postsCategory, loadedPage);

    useEffect(() => {
        // if (isChatPage) {
        //     fetchSwiping()
        // } else {

        fetchTotalProfileCount()
        // }
        // getProfileData()
    }, [props]);

    useEffect(() => {
        const fromSearchFn = () => {
            setShowDropdown(true);
            setReportedfilter(fromSearchID)
            console.log("fromSearchID", fromSearchID, fromSearch)
        }
        if (fromSearch) fromSearchFn();
    }, [fromSearch]);

    const handleHistoryClick = () => {
        setShowDropdown(currentShowDropdown => !currentShowDropdown);
        // console.log("showDropdown", !showDropdown);
        posts = []
        let prePost = [];
        if (!showDropdown) {
            // console.log("!showDropdown", showDropdown);
            setLastPost(null);
            setIsLoading(true);
            postsCategory = prePost
            getPosts({

                // reported: reported,
                page: 1,
                // unread: unread,
                chatHistory: true,
                // userID: reportedFilter ? reportedFilter : false,
            })
                .then(({ lastSeen }) => {
                    setLastSeen(lastSeen);
                    setIsLoading(false);
                })
                .catch((err) => {
                    console.error("Error with getting posts", err);
                });
        } else {
            console.log("showDropdown", showDropdown);

            setLastPost(null);
            setIsLoading(true);
            getPosts({
                // reported: reported,
                page: 1,
                // unread: unread,
                chatHistory: false,
            })
                .then(({ lastSeen }) => {
                    setLastSeen(lastSeen);
                    setIsLoading(false);
                })
                .catch((err) => {
                    console.error("Error with getting posts", err);
                });
        }
    };


    const fetchTotalProfileCount = () => {
        setIsLoading(true);
        // console.log("Not is chat page", isChatPage);
        db
            .collection("chat_report")
            .doc("info")
            .get()
            .then((res) => {
                // const { profile_available, profile_total } = res.data()
                // setTotalProfileCount(res.data().open_report_counter)
                console.log("Profile info res", res.data())
                setProfileInfo(res.data())
                setIsLoading(false);
            })
            .catch(err => {
                console.log(err);
            })
    }

    const getProfileData = async () => {
        const profileData = []
        db.collection("profile")
            .limit(15)
            .get()
            .then(function (querySnapshot) {
                querySnapshot.forEach(function (doc) {
                    // doc.data() is never undefined for query doc snapshots
                    // console.log(doc.id, " => ", doc.data());
                    profileData.push(doc.data())
                });
                // console.log(profileData);
            })
            .catch(function (error) {
                console.log("Error getting documents: ", error);
            });
    }
    //-----------------------------------------------------------------------------------

    let postsLoading = useSelector(selectPostsLoading);
    // console.log("postLoading", postsLoading);
    let activePost = useSelector(selectActivePost);
    const isActivePostBoosted = posts[activePost]?.boost_request;

    let dispatch = useDispatch();
    const { reported, unread, filter, chatHistory } = props;

    useEffect(() => {
        getPosts({ reported: reported, page: 1, unread: unread, filter: filter, chatHistory: chatHistory, userID: reportedFilter }).then(
            ({ lastSeen }) => {
                setLastSeen(lastSeen);
            }
        );
    }, [reported, unread, filter, chatHistory]);

    function nextPage() {
        setIsLoading(true);
        let query;
        let startAfterValue;
        if (selected_mod_uid) {
            // console.log('Inside selection mod condition');
            query = {
                reported: props.reported,
                unread: props.unread,
                filter: selected_mod_uid,
                page: loadedPage + 1,
                startAfter: lastSeen,
                uid: selected_mod_uid,
            };
            if (lastSeen && lastSeen.data) {
                startAfterValue = lastSeen.data().date;
            } else {
                startAfterValue = null;
                // setIsLoading(false)
            }

        }
        else if (showDropdown === true) {
            // console.log('Inside chatHistory condition');
            query = {
                reported: props.reported,
                unread: props.unread,
                page: loadedPage + 1,
                startAfter: lastSeen,
                chatHistory: true,
                userID: reportedFilter ? reportedFilter : false,
            };
            if (lastSeen && lastSeen.data) {
                startAfterValue = lastSeen.data().date;
            } else {
                startAfterValue = null;
                // setIsLoading(false)
            }
        }
        else {
            // console.log('Inside All post condition');
            query = {
                reported: props.reported,
                page: loadedPage + 1,
                unread: props.unread,
                startAfter: lastSeen,
            };
            if (lastSeen && lastSeen.data) {
                startAfterValue = lastSeen.data().date;
            } else {
                startAfterValue = null;
                // setIsLoading(false)
            }
        }

        getPosts(query)
            .then(({ lastSeen }) => {
                setLastSeen(lastSeen);
                setIsLoading(false);
            })
            .catch((err) => {
                console.error("Error with getting posts", err);
                setIsLoading(false);
            });
    }

    function previousPage() {
        if (loadedPage - 1) dispatch(changePage(loadedPage - 1));
        // setIsLoading(true);
    }

    function lock(reason = {}, post = {}) {
        db.collection("banned_moderation")
            .doc(posts[activePost]?.userID)
            .set(
                {
                    ...reason,
                    unlock_all: false,
                    report_counter_mod: firestore.FieldValue.increment(1),
                    // banned_unlock_date: firestore.Timestamp.fromDate(
                    //     dayjs().add(3, "hour").toDate()
                    // ),
                    report_mod_uid: user?.uid,
                    report_mod_date: firestore.FieldValue.serverTimestamp(),
                    last_post_reported: true,
                    postText: post?.postText ?? "",
                    imageUrlPost: post?.imageUrlPost ?? "",
                },
                { merge: true }
            );
    }

    function deletePost(reason, post, ban = false) {
        let postToDelete = post ?? posts[activePost];

        deleteHistory({
            post: postToDelete,
            user,
            reason,
            location,
            ...(ban ? { action: "ban" } : {}),
        });

        log("remove_img_mod", reason?.reason_report);

        deleteNotification({ fcm_token: postToDelete.fcm_token });

        sendDeleteMessage({
            username: postToDelete.username,
            uid: postToDelete.userID,
            emoji: postToDelete.emoji,
            reason,
        });


        lock(reason, postToDelete);

        adminAction({ newPosts: props.unread });

        firebaseDeletePost(postToDelete.id, postToDelete.userID, props.reported);

        if (reason) {
            setReasonPrompt(false);
            setTextPrompt(false);
            setBanPrompt(false);
        }
    }

    function adminAction({ newPosts = false }) {
        const actionObject = newPosts
            ? {
                new_post_uid: user.uid,
                new_post_date: firestore.FieldValue.serverTimestamp(),
            }
            : {
                last_action: Date.now(),
                userID: user.uid,
                last_action_timestamp: firestore.FieldValue.serverTimestamp(),
            };
        db.collection("admin").doc("info").set(actionObject, { merge: true })
            .then(res => {

            })

    }

    function log(event, reason = null) {
        const logObject = {
            event_type: event,
            email: "email@gmail.com",
            userID: posts[activePost].userID,
            mod_location: props.unread
                ? "new_posts"
                : props.reported
                    ? "all_reported"
                    : "all_posts",
            imageUrlPost: posts[activePost].imageUrlPost,
            postText: posts[activePost].postText,

            data: {
                reason,
                ...(posts[activePost]?.response_time &&
                    (event === "skip_post" ||
                        event === "keep_img_mod" ||
                        event === "remove_img_mod")
                    ? {
                        response_time: dayjs().unix() - posts[activePost]?.response_time,
                    }
                    : {}),
            },
        };

        logAnalytics(logObject).catch((err) => console.error("log err", err));
    }

    function deleteBio() {
        db.collection("users")
            .doc(posts[activePost].userID)
            .set(
                {
                    bio: "",
                },
                { merge: true }
            )
            .then(() => setBio(""));
    }

    function deleteVerificationImg() {
        setTextPrompt(false);
        setVerifyImg("");
        db.collection("users").doc(posts[activePost].userID).set(
            {
                verifImgUrl: "",
            },
            { merge: true }
        );
    }

    function deleteUsername() {
        db.collection(props.reported ? "moderation_post" : "public_post")
            .doc(posts[activePost].userID)
            .set({ username: "inappropriate" }, { merge: true })
            .then(() => { });

        if (props.reported) {
            db.collection("public_post")
                .doc(posts[activePost].userID)
                .set({ username: "inappropriate" }, { merge: true })
                .then(() => { });
        }

        db.collection("users")
            .doc(posts[activePost].userID)
            .set(
                {
                    username: "inappropriate",
                },
                { merge: true }
            )
            .then(() => {
                setTextPrompt(false);
                dispatch(
                    setPostUsername({
                        username: "inappropriate",
                        reported: props.reported,
                        unread: props.unread,
                    })
                );
            })
            .catch((err) => {
                console.log(err);
            });
    }

    function seePost() {
        // console.log("++++++++++", isLoading);
        db.collection(props.reported ? "moderation_post" : "public_post")
            .doc(posts[activePost].id)
            .set(
                {
                    post_seen_mod: true,
                    post_seen_by: firestore.FieldValue.arrayUnion(user.uid),
                    seen_mod_date: firestore.FieldValue.serverTimestamp(),
                },
                { merge: true }
            );
    }
    const keepPost = async () => {
        console.log(roomIdData, "-----------roomIdData-----------------");
        // return;
        // alert(roomIdData.reportedUserID)
        // console.log(userId, roomID, "docID-----------------------------");
        const { reporting_uid, reported_uid, roomID, type } = posts[activePost];
        if (type) {
            res_chat_report_swiping({ reporting_uid, reported_uid, roomID, mod_res: 1 })
            next();
            return;
        } else {
            chat_report_mod({ reportedID: roomIdData?.uid_reported, roomID: roomIdData?.roomID, mod_res: 1 })
        }
        // .then(async (res) => {
        // console.log(res, "----------Chat Response-------------------");

        let openCasePosts = [];
        let openCases = await db.collection("chat_report").doc(roomIdData.uid_reported).collection("report_list")
            .where("need_moderation", "==", true)
            .orderBy("date", "desc").limit(30).get();

        // console.log(roomIdData.uid_reported, "--------------Reported---------------------");
        openCases.forEach((item, i) => {
            let post = { ...item.data(), id: item.id };
            openCasePosts.push(post);
        })
        console.log(k, n, openCasePosts, k < openCasePosts.length);
        if (k < openCasePosts.length - 1) {
            setK(k + 1)
            getRoomId(openCasePosts[0].uid_reported, n)
        } else {
            setK(0)
            setN(0)
            next()
        }


        // next()
        // }).catch((err) => {
        //     console.log(err, "-----------Chat Err------------------------");
        // })

        adminAction({ newPosts: props.unread });

        keepHistory({ post: posts[activePost], user, location });

        if (!props.reported) {
            seePost();
            next();
            return;
        }

        const postID = posts[activePost].id;

        keepNotification({ fcm_token: posts[activePost].fcm_token });

        db.collection("banned_moderation")
            .doc(postID)
            .set({ last_post_reported: false }, { merge: true });

        db.collection("public_post")
            .doc(postID)
            .set(
                {
                    finish_processing: true,
                    img_state: 5,
                    post_seen_mod: true,
                    datePost: firestore.FieldValue.serverTimestamp(),
                    post_seen_by: firestore.FieldValue.arrayUnion(user.uid),
                    seen_mod_date: firestore.FieldValue.serverTimestamp(),
                    ...(isActivePostBoosted ? { boost_safe: true } : {}),
                },
                { merge: true }
            )
            .then((res) => {
                log("keep_img_mod");
                // console.log("keep success ", postID);
            })
            .catch((err) => {
                console.error(err);
            });

        if (props.reported) {
            db.collection("moderation_post")
                .doc(postID)
                .set(
                    {
                        moderated: true,
                        post_seen_mod: true,
                        post_seen_by: firestore.FieldValue.arrayUnion(user.uid),
                        seen_mod_date: firestore.FieldValue.serverTimestamp(),
                        last_post_reported: false,
                    },
                    { merge: true }
                )
                .then(() => { })
                .catch((err) => {
                    console.log(err);
                });
        }
        next(false);
        dispatch(removePost({ id: postID }));
    };

    const displayBan = async () => {
        const reason = prompt("Reason for Ban")
        if (!reason || reason == "") {
            // console.log("Please Fill Reason");

        } else {
            const { reporting_uid, reported_uid, roomID, type } = posts[activePost];
            if (type) {
                res_chat_report_swiping({ reporting_uid, reported_uid, roomID, mod_comment: reason, mod_res: 3 });
                next();

            } else {
                const data = { reportedID: roomIdData?.uid_reported, roomID: roomIdData?.roomID, mod_res: 3, reason: reason }
                chat_report_mod(data)
                // .then(async (res) => {
                //     console.log(res, "----------Chat Response-------------------");
                // --------------------------------------
                let openCasePosts = [];
                let openCases = await db.collection("chat_report").doc(roomIdData.uid_reported).collection("report_list")
                    .where("need_moderation", "==", true)
                    .orderBy("date", "desc").limit(30).get();

                // console.log(roomIdData.uid_reported, "--------------Reported---------------------");
                openCases.forEach((item, i) => {
                    let post = { ...item.data(), id: item.id };
                    openCasePosts.push(post);
                })
                // console.log(k, n, openCasePosts.length, k < openCasePosts.length);
                if (k < openCasePosts.length - 1) {
                    setK(k + 1)
                    getRoomId(openCasePosts[0].uid_reported, n)
                } else {
                    setK(0)
                    setN(0)
                    next()
                }
            }

            // }).catch((err) => {
            //     console.log(err, "-----------CHat Err------------------------");
            // })
        }
        // return console.log(reason, "------------Reason-----------");
        // return


        return
        setBanPrompt(true);
    };

    function ban() {
        let uid = posts[activePost].userID;
        db.collection("banned_moderation")
            .doc(uid)
            .set({ banned: true }, { merge: true })
            .then(() => {
                adminAction({ newPosts: props.unread });
                lock();
                deletePost(null, null, true);
                log("ban_img_mod");
            })
            .catch((err) => {
                console.error("failed ban", err);
            });
        setBanPrompt(false);
    }

    function hide() {
        let uid = posts[activePost].userID;
        db.collection("banned_moderation")
            .doc(uid)
            .set({ hide: true }, { merge: true })
            .then(() => {
                log("ban_img_mod");
                lock();
                adminAction({ newPosts: props.unread });
                deletePost(null, null, true);
            })
            .catch((err) => {
                console.log("failed hide", err);
            });
        setBanPrompt(false);
    }

    const supreme = () => {
        let uid = posts[activePost].userID;
        db.collection("banned_moderation")
            .doc(uid)
            .set({ supreme: true }, { merge: true })
            .then(() => {
                log("ban_img_mod");
                lock();
                adminAction({ newPosts: props.unread });
                deletePost(null, null, true);
            })
            .catch(() => {
                console.log("failed supreme");
            });
        setBanPrompt(false);
    };

    function handleSetCopy(id) {
        navigator.clipboard.writeText(id).then(() => {
            console.log("Copied reportedUser.userId =", id)
        })
    }

    function handleSetCopyReportingUser(id) {
        navigator.clipboard.writeText(id)
            .then(() => {
                console.log("Copied reportingUser.userId:", id);
            })
    }

    const next = (skip = true) => {
        log("skip_post");

        seePost();
        if (!skip) return;
        if (activePost + 1 === posts.length) {

            nextPage();
            dispatch(setActivePost(-1));
        } else {
            logAnalytics({
                event_type: "next_history_webapp",
                data:{
                    mod_uid: posts[activePost]?.mod_uid,
                    location: "chat"
                }
            }).catch((err) => console.error("log err", err));
            dispatch(nextPost());
        }
        // console.log("isLoading", isLoading);
    };

    const back = () =>{
        if (activePost === 0 && loadedPage > 1){
            previousPage();
            dispatch(setActivePost(-1));
            return;
        }
        dispatch(setActivePost(activePost - 1));
    }

    function handleKeyDown(e) {
        setKey(e.key);
    }

    function handleKeyUp(e) {
        setKey("");

        if (e.key === " ") {
            if (activePost === -1) {
                dispatch(setActivePost(0));
            }
            else {
                //Fetch extra 10 messages
                // getExtraChatData(posts[k]?.roomID)
                //     .then((res) => {
                //         if (res?.chatPosts) {
                //             setRoomIdData({
                //                 ...roomIdData,
                //                 chat: res?.chatPosts?.reverse(),
                //                 chatLastSeen: res?.lastSeen,
                //             })
                //         }
                //     })
                //     .catch((err) => {
                //         alert(err);
                //     })
            }
        }

        if (e.key === "Escape") {
            if (showPopup?.show) return setShowPopup(currentState => {
                return { show: !currentState.show, ID: undefined };
            })
            else {
                dispatch(setActivePost(-1));
            }
        }

        if (e.key === 'a') {
            handleAge();
        }

        if (e.key === "q") {
            handleSetCopy(Id || roomIdData?.uid_reported)
            if (!showPopup.show) setShowPopup(currentState => {
                return { show: true, ID: Id || roomIdData?.uid_reported };
            }
            )
        }

        if (e.key === "w") {
            handleSetCopyReportingUser(Id2 || roomIdData?.uid_reporting);

            if (!showPopup.show) setShowPopup(currentState => {
                return { show: true, ID: Id2 || roomIdData?.uid_reporting };
            }
            )
        }
        if (e.key === "ArrowLeft") {
            back()
        };

        if (e.key === "g") {
            back()
        };

        if (e.key === "ArrowRight") {
            if (activePost == -1) {

                nextPage();
            }
        };

        if (e.key === "h") {
            nextPage();
        };

        if (activePost !== -1 && activePost < posts.length) {

            if (e.key === "ArrowLeft") {
                // previousPage()
                back()
            };
            if (e.key === "g") {
                back()
                // previousPage()
            };

            if (e.key === "ArrowRight") {
                console.log("activePost !== -1", isLoading);
                next()
                // nextPage();
            };
            if (e.key === "h") {
                next()
                // nextPage();
            };

            if (e.key === "f") deleteUser(userId, roomData);
            if (e.key === "j") keepPost();
            // if (e.key === "k") displayBan();
            if (e.key === "i") displayBan();
            if (e.key === "z") {
                deleteUser(userId, roomData);
                deleteForSelling()
            };
            // if (e.key === "x")
            //     deletePost({
            //         reason_report_int: 0,
            //         reason_report: "Against guidelines",
            //     });

            // if (
            //     (e.key === "2" && reasonPrompt) ||
            //     (e.key === "a" && !reasonPrompt && !banPrompt && !textPrompt)
            // )
            //     deleteForUnderage();

            // if (e.key === "a") {
            //     setTextPrompt(false);
            //     setBanPrompt(false);
            //     setReasonPrompt(false);
            // }

            // if ((e.key === "1" && reasonPrompt) || e.key === "d") deleteForNudity();

            // if ((e.key === "3" && reasonPrompt) || e.key === "c") deleteForSelling();

            // if ((e.key === "4" && reasonPrompt) || e.key === "s") deleteforSexual();
        }

        // if (banPrompt) {
        //     if (e.key === "1") ban();
        //     if (e.key === "2") hide();
        //     if (e.key === "3") supreme();
        // }

        // if (e.key === "1" && textPrompt) deleteBio();
        // if (e.key === "2" && textPrompt) deleteUsername();

        // if (e.key === "5" && reasonPrompt)
        //     deletePost({
        //         reason_report_int: 5,
        //         reason_report: "Scam",
        //     });
        // if (e.key === "6" && reasonPrompt)
        //     deletePost({
        //         reason_report_int: 6,
        //         reason_report: "Illegal",
        //     });
        // if (e.key === "0" && reasonPrompt)
        //     deletePost({
        //         reason_report_int: 0,
        //         reason_report: "Against guidelines",
        //     });
    }

    useEffect(() => {
        if (activePost == -1) {
            // console.log("----event outer");
            document.addEventListener("keyup", handleKeyUp);
            // console.log(posts, "postss")
        }

        return function () {
            document.removeEventListener("keyup", handleKeyUp);
        };
    }, [activePost]);

    function deleteForNudity() {
        handleDelete({
            reason_report_int: 1,
            reason_report: "Nudity / Partial Nudity",
        });

        // deletePost({
        //   reason_report_int: 1,
        //   reason_report: "Nudity / Partial Nudity",
        // });
    }

    // console.log("props.reportedUserId", userId, roomIdData);
    async function deleteForSelling() {

        if (posts[0].type) {
            return;
        }

        await ban_user_mod({
            uid: userId,
            action: "ban",
            reason: "selling",
            location: "chat",
            roomID: roomIdData?.roomID
        })
            .then((res) => {
                console.log("ban_user_mod response:", res)
                if (res.data.status !== 1) {
                    alert(`problem deleting: ${res.data.message}`)
                }
            })
            .catch((err) => {
                alert(`problem deleting for: ${err}`);
                console.error("error with ban_user_mod:", err);
            })
        // window.alert(result.data.message);
        // console.log(result.data.message);

    }

    function deleteforSexual() {
        deletePost({
            reason_report_int: 4,
            reason_report: "Suggestive / Sexual Suggestion",
        });
    }

    function deleteForUnderage() {
        handleDelete({
            reason_report_int: 2,
            reason_report: "Underage",
        });
    }

    useEffect(() => {
        document.addEventListener("keydown", handleKeyDown);
        document.addEventListener("keyup", handleKeyUp);

        return function () {
            document.removeEventListener("keydown", handleKeyDown);
            document.removeEventListener("keyup", handleKeyUp);
        };
    });

    useEffect(() => {
        // to clear when going to posts pages (All Posts - New Posts - All Reported)
        return () => {
            dispatch(clearPosts());
        }
    }, [])

    function PageNavigation() {
        return (
            <>
                <div className="page-navigation-btn">
                    {user.uid === "H8rw3oWoEZZAcIAtVtUB5V82zZF2" && (
                        <Button
                            label="Create test"
                            onClick={() => createTest({ reported: props.reported })}
                        />
                    )}


                    <div className="paginate-btn">
                        <Button onClick={previousPage} label="Previous" />
                        <Button label={loadedPage} className="p-button-outlined " />
                        <Button onClick={nextPage} label="next" />
                    </div>

                </div>
                <div
                    className="swiping_div"
                    style={{ top: '4.5vw', right: '10vw' }}
                >
                    <button
                        className="history-btn"
                        onClick={() => {
                            // setPage(0);
                            // setPageNumber(1)
                            handleHistoryClick();
                            logAnalytics({
                                event_type: "show_history_webapp",
                                data: {
                                    mod_uid: posts[activePost]?.mod_uid,
                                    location: "chat"
                                }
                            }).catch((err) => console.error("log err", err));
                        }}
                        style={{
                            border: 'none',
                            backgroundColor: 'transparent',
                            cursor: 'pointer',
                        }}
                    >
                        <img src="../history.png" width="22px" />
                    </button>
                </div>
            </>
        );
    }

    async function deleteUser() {
        // return alert("This is delete Button.")
        console.log("This is delete Button");

        // console.log(reporting_uid, reported_uid, roomID, mod_res, mod_comment, type, "2222222222222222222");
        const { reporting_uid, reported_uid, roomID, type } = posts[activePost];
        console.log(reporting_uid, reported_uid, roomID, type, "type");
        if (type) {
            const result = await res_chat_report_swiping({ reporting_uid, reported_uid, roomID, mod_res: 2 })
            console.log(result, "result");
            // next()
        } else {
            chat_report_mod({ reportedID: roomIdData?.uid_reported, roomID: roomIdData?.roomID, mod_res: 2 })

            // .then(async (res) => {
            //     console.log(res, "----------Chat Response-------------------");

            let openCasePosts = [];
            let openCases = await db.collection("chat_report").doc(roomIdData.uid_reported).collection("report_list")
                .where("need_moderation", "==", true)
                .orderBy("date", "desc").limit(30).get();

            // console.log(roomIdData.uid_reported, "--------------Reported---------------------");
            openCases.forEach((item, i) => {
                let post = { ...item.data(), id: item.id };
                openCasePosts.push(post);
            })
            console.log(k, n, openCasePosts, k < openCasePosts.length, "qqqqqqqqqqqqqqqqqqqqqqqqqqq");
            if (k < openCasePosts.length - 1) {
                setK(k + 1)
                getRoomId(openCasePosts[0].uid_reported, n)
            } else {
                setK(0)
                setN(0)
                next()
            }
        }


        // next()
        // }).catch((err) => {
        //     console.log(err, "-----------Chat Err------------------------");
        // })
        // alert("Delete User")
    }

    async function getChatData(roomId) {
        let chatPosts
        try {
            let chatData = await db.collection("chat").doc(roomId).collection("discussion").orderBy("date", "desc").limit(21).get()
            let chatPosts = []
            let lastSeenMessage = null
            chatData.forEach((item, i) => {
                let post = { ...item.data(), id: item.id };
                chatPosts.push(post);
            })
            if (chatPosts.length > 0) {
                lastSeenMessage = chatPosts[chatPosts.length - 1];
            }
            return { chatPosts: chatPosts, lastSeen: lastSeenMessage }
        } catch (error) {
            return chatPosts = []
        }
    }

    async function getExtraChatData(roomId) {
        let chatPosts
        try {
            let chatQuery = db.collection("chat").doc(roomId).collection("discussion")
            if (roomIdData?.lastSeen) {
                chatQuery = chatQuery.startAfter(roomIdData.lastSeen);
            }
            let chatData = await chatQuery.orderBy("date", "desc").limit(10).get()
            let chatPosts = roomIdData?.chat || [];
            let lastSeenMessage = null;
            chatData.forEach((item, i) => {
                let post = { ...item.data(), id: item.id };
                chatPosts.push(post);
            })
            if (chatPosts.length > 0) {
                lastSeenMessage = chatPosts[chatPosts.length - 1];
            }
            return { chatPosts: chatPosts, lastSeen: lastSeenMessage }
        } catch (error) {
            console.error("Error fetching chat data:", error);
            return { chatPosts: null, lastSeen: null };
        }
    }

    async function getReportedUser(uid_reported) {
        let reportedUserData
        try {
            let reportedUser = await db.collection("users").doc(uid_reported).get();
            reportedUserData = reportedUser.data()
            return reportedUserData
        } catch (error) {
            return reportedUserData = {}
        }
    }

    async function getReportingUser(uid_reporting) {
        let reportingUserData
        try {
            let reportingUser = await db.collection("users").doc(uid_reporting).get()

            reportingUserData = reportingUser.data()
            return reportingUserData
        } catch (error) {
            return reportingUserData = {}
        }
    }

    async function getOpenCaseUser(uid_reported) {
        let openCasePosts = []
        try {
            let openCases = await db.collection("chat_report").doc(uid_reported).collection("report_list")
                .where("need_moderation", "==", true)
                .orderBy("date", "desc").limit(30).get();

            openCases.forEach((item, i) => {
                let post = { ...item.data(), id: item.id };
                openCasePosts.push(post);
            })
            return openCasePosts
        } catch (error) {
            return openCasePosts = []
        }
    }

    async function getCloseCaseUser(uid_reported) {
        let closeCasePosts = []
        try {
            let closeCases = await db.collection("chat_report").doc(uid_reported).collection("report_list")
                .where("need_moderation", "==", false)
                .orderBy("date", "desc").limit(30).get();

            closeCases.forEach((item, i) => {
                let post = { ...item.data(), id: item.id };
                closeCasePosts.push(post);
            })

            return closeCasePosts
        } catch (error) {
            return closeCasePosts = []
        }
    }

    async function getRoomId(userID, n) {
        try {
            if (!n) {
                k = 0
                setN(n + 1)
            } else {
                k = n
                setN(n + 1)
            }
            // Get RoomID Query
            setRoomIdData("")

            const getRoomIdData = await db.collection("chat_report").doc(userID).collection("report_list")
                .where("need_moderation", "==", true)
                .orderBy("date", "desc").limit(20).get()

            let posts = []
            // let posts
            getRoomIdData?.forEach((item, i) => {
                let post = { ...item.data(), id: item.id };
                posts.push(post);
                // posts = post;
            })
            console.log(userID, posts, k, "posts in functionnnnnnnnnnn");

            // // Get Chat Base on roomId
            // let chatPosts = await getChatData(posts[k]?.roomID)

            // // Reported User query
            // let reportedUserData = await getReportedUser(posts[k]?.uid_reported)

            // // Reporting User query
            // let reportingUserData = await getReportingUser(posts[k]?.uid_reporting)

            // // Open Cases query
            // let openCasePosts = await getOpenCaseUser(posts[k]?.uid_reported)

            // // Close Cases query
            // let closeCasePosts = await getCloseCaseUser(posts[k]?.uid_reported)

            // Prepare all promises without waiting for them to resolve immediately
            const chatPostsPromise = getChatData(posts[k]?.roomID);
            const reportedUserDataPromise = getReportedUser(posts[k]?.uid_reported);
            const reportingUserDataPromise = getReportingUser(posts[k]?.uid_reporting);
            const openCasePostsPromise = getOpenCaseUser(posts[k]?.uid_reported);
            const closeCasePostsPromise = getCloseCaseUser(posts[k]?.uid_reported);

            // Use Promise.all to run them in parallel and wait for all of them to resolve
            const [chatPosts, reportedUserData, reportingUserData, openCasePosts, closeCasePosts] = await Promise.all([
                chatPostsPromise,
                reportedUserDataPromise,
                reportingUserDataPromise,
                openCasePostsPromise,
                closeCasePostsPromise
            ]);


            setRoomData(posts[k]?.roomID)
            setUserId(userID)

            setRoomIdData({
                roomID: posts[k]?.roomID,
                uid_reported: posts[k]?.uid_reported,
                uid_reporting: posts[k]?.uid_reporting,
                reason: posts[k]?.reason,
                // posts: posts,
                // ---------------------------------------
                // chatId: chatPosts.id,
                // chatStatus: chatPosts.status,
                // chatText: chatPosts.text,
                // chatUserID: chatPosts.userID,
                chat: chatPosts?.chatPosts?.reverse(),
                chatLastSeen: chatPosts?.lastSeen,
                // -----------------------------------------
                reportedUsername: reportedUserData?.username,
                reportedUserID: reportedUserData?.userID,
                reportedProfileImage: reportedUserData?.first_profile_url,
                reportedGender: reportedUserData?.gender,
                reportedAge: reportedUserData?.age,
                reportedCountry: reportedUserData?.country,
                reportedDate: reportedUserData?.dateKeys,
                // -----------------------------------------
                reportingUsername: reportingUserData?.username,
                reportingUserID: reportingUserData?.userID,
                reportingProfileImage: reportingUserData?.first_profile_url,
                reportingGender: reportingUserData?.gender,
                reportingAge: reportingUserData?.age,
                reportingCountry: reportingUserData?.country,
                reportingDate: reportingUserData?.dateKeys,
                // ----------------------------------------------
                // openCasePostsReason: openCasePosts?.reason,
                // openCasePostsUsername: openCasePosts?.username_reporting,
                // openCasePostsSinceDate: openCasePosts?.date.seconds,
                openCase: openCasePosts,

                // ----------------------------------------------
                // closeCasePostsReason: closeCasePosts?.reason,
                // closeCasePostsUsername: closeCasePosts?.username_reporting,
                // closeCasePostsSinceDate: closeCasePosts?.date.seconds
                closeCase: closeCasePosts
            })

        } catch (error) {
            console.log(error, "Error in Database Query");
            setRoomIdData("")
        }
    }

    function loadAdmins() {
        const adminsArray = [];
        db.collection('admin')
            .where('admin', '==', true)
            .get()
            .then((snapshot) => {
                snapshot.forEach((admin) => {
                    const { userID } = admin.data();
                    // console.log(userID, '-------------------userid');
                    adminsArray.push({ label: userID, value: userID });
                });
                setAdmins((admins) => [...admins, ...adminsArray]);
            });
    }

    useEffect(loadAdmins, []);

    // function handleDropdownChange(e) {

    //     selected_mod_uid = e.value;
    //     console.log('New selection:', e.value);
    //     setAdminFilter(e.value);
    //     setLastPost(null);
    //     setIsLoading(true);

    //     // Define the filter variable based on the selected value
    //     let filter = e.value !== "all_posts" ? true : false;


    //     getPosts({
    //         reported: reported,
    //         page: 1,
    //         unread: unread,
    //         filter: filter,
    //         uid: selected_mod_uid,
    //     }).then(({ lastSeen }) => {
    //         setLastSeen(lastSeen);
    //         setIsLoading(false);

    //     }).catch((err) => {
    //         console.error("Error with getting posts", err);
    //     });

    // }

    function handleDropdownChange(e) {
        setReportedfilter('')
        selected_mod_uid = e.value;
        // console.log('New selection:', e.value);
        setAdminFilter(e.value);
        setLastPost(null);
        setIsLoading(true);
        let filter = e.value !== "all_posts" ? true : false;
        // console.log("filter======", filter);
        getPosts({
            reported: reported,
            page: 1,
            unread: unread,
            filter: filter,
            uid: selected_mod_uid,
        })
            .then(({ lastSeen }) => {
                setLastSeen(lastSeen);
                setIsLoading(false);
            })
            .catch((err) => {
                console.error("Error with getting posts", err);
            });

    }

    function handleDropdownFilter(e) {

        setLastPost(null);
        setIsLoading(true);
        setActionFilter(e.value);

        getPosts({
            reported: reported,
            page: 1,
            unread: unread,
            filter: true,
            mod_res: e.value,
            chatHistory: true,
        })
            .then(({ lastSeen }) => {
                setLastSeen(lastSeen);
                setIsLoading(false);
            })
            .catch((err) => {
                console.error("Error with getting posts", err);
            });

    }

    const getCountOfData = async () => {
        let count;
        if (showDropdown) {
            setCountResponse("-");
            return;
        }
        if (pathname === '/swiping/chat') {
            count = await get_counter_mod({ app: "swiping", section: "swiping", subsection: "chat" });
        } else {
            count = await get_counter_mod({ app: "addchat", section: "chat" });
        }
        if (count?.data?.status === 1) {
            setCountResponse(count.data.counter);
        }
        //   console.log(count, "count");
    }

    useEffect(() => {
        getCountOfData()
        setPostsCategory([])
    }, [isChatPage, showDropdown]);

    // useEffect(() => {
    //     console.log("ppooopppuuuppp", showPopup)
    // }, [showPopup])

    const handleSearchUser = (uid) => {
        console.log("uid", uid)
        setReportedfilter(uid)
        // get_close_cases(uid)
    }

    const fetchByUserID = async () => {
        setAdminFilter(undefined)
        setLastPost(null);
        setIsLoading(true);
        postsCategory = []
        getPosts({

            // reported: reported,
            page: 1,
            // unread: unread,
            chatHistory: showDropdown ? true : false,
            userID: reportedFilter ? reportedFilter : false,
            firstFetch: true,
        })
            .then(({ lastSeen }) => {
                setLastSeen(lastSeen);
                setIsLoading(false);
            })
            .catch((err) => {
                console.error("Error with getting posts", err);
            });
    }

    useEffect(() => {
        if (showDropdown) fetchByUserID()
        console.log("reportedFilter", reportedFilter)
    }, [reportedFilter])

    const handleAge = () => {
        if (!(Id || roomIdData?.uid_reported)) {
            return;
        }
        // setShowSuccess(true);
        // return console.log(Id || roomIdData?.uid_reported)
        age_verif_mod({
            req_age: true,
            uid: Id || roomIdData?.uid_reported
        }).then((res) => {
            if (res?.data?.status != 1) {
                console.error("age_verif_mod error:", res?.data?.message);
                alert(`age_verif_mod error: ${res?.data?.message}`)
            } else {
                setShowSuccess(true);
            }
        }).catch((err) => {
            alert(`age_verif_mod error: ${err}`)
            console.error("age_verif_mod", err)
        })
    }

    // const get_close_cases = (uid) => {
    //     if (uid) db.collection("chat_report")
    //         .doc(uid)
    //         .collection("report_list")
    //         .where("need_moderation", "==", false)
    //         .orderBy("date", "desc")
    //         .limit(30)
    //         .get()
    //         .then((res) => {
    //             console.log("close cases", res.docs.map(doc => doc.data()))
    //             // return setCloseCases(res.docs.map(doc => doc.data()))
    //         })
    // }

    return (
        <div>
            <Snackbar
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                }}
                open={showSuccess}
                autoHideDuration={2500}
                onClose={() => setShowSuccess(false)}
            >
                <Alert severity="success" sx={{ width: '100%' }}>
                    "Successfully sent user to Age Verification"
                </Alert>
            </Snackbar>
            {showPopup.show && showPopup.ID &&
                <div style={showPopup?.show && showPopup?.ID ? { backgroundColor: "rgba(0, 0, 0, 0.4)", width: "100vw", height: "100vh", zIndex: 900, position: "fixed", top: 0, left: 0 } : {}}>
                    <div className="chatpopup_container" style={{
                        position: 'fixed', // Or 'absolute', if you prefer
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)', // Centers the div
                        width: '80vw',
                        border: '2px solid grey',
                        borderRadius: '10px',
                        backgroundColor: 'white',
                        zIndex: 900,
                        // Ensure content is scrollable if it overflows
                        height: '90vh',
                        overflowY: 'scroll'
                    }}>
                        <button className="close" style={{ position: "sticky" }} onClick={() =>
                            setShowPopup(currentState => {
                                return { show: !currentState.show, ID: undefined };
                            }
                            )}>
                            Close
                        </button>
                        <SearchUser deletePosts={false} isDevelopment={false} userID={showPopup.ID ? showPopup.ID : undefined} />
                    </div>
                </div>
            }

            <div className="verification-counter">
                {countResponse}
            </div>

            {showDropdown && pathname == "/swiping/chat" &&
                <div className="action-filter-swiping">
                    <Dropdown
                        options={actionOptions}
                        value={actionFilter}
                        onChange={(e) => {
                            setPage(0);
                            setLastPost(null);
                            handleDropdownFilter(e)
                        }}
                        placeholder="All"
                        showClear={showClearIcon}
                    />
                </div>
            }

            {<PageNavigation />}
            {/* {console.log(selected_mod_uid, "===========props.by_mod========")} */}
            <p style={{ position: "absolute", left: "5vw", top: "72px", fontWeight: "bold", fontSize: "15px" }}> {dayjs.unix(profileInfo?.last_mod_date?.seconds).fromNow()} • {profileInfo?.last_mod_uid} </p>
            {showDropdown && (
                <div
                    style={{
                        display: 'flex',
                        // justifyContent: 'end',
                        marginRight: '30px',
                    }}
                >
                    <div style={{ marginRight: "auto", marginLeft: "30px", marginTop: "20px" }}>
                        <input
                            type="text"
                            placeholder="Search by User ID"
                            style={{
                                height: "30px",
                                width: "200px",
                                borderRadius: "5px",
                                border: "1px solid #ccc",
                            }}
                            onChange={(e) => handleSearchUser(e.target.value)}
                            value={reportedFilter}
                        />
                    </div>
                    <div
                        className="admin-filter"
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            maxWidth: '400px',
                        }}
                    >
                        <Dropdown
                            options={admins}
                            onChange={handleDropdownChange}
                            value={adminFilter}
                            showClear
                            placeholder="All Admins"
                        />

                        <input
                            placeholder="All Admins"
                            style={{ marginTop: '10px', height: '28px' }}
                            type="datetime-local"
                            onChange={(e) => {
                                // const selectedDate = new Date(e.target.value).getTime();
                                // const currentDate = new Date().getTime();

                                // if (selectedDate <= currentDate) {
                                //     // setRange(e.target.value);

                                // } else {
                                //     // Handle case where selected date is in the future
                                //     console.log('Selected date is in the future.');
                                // }
                            }}
                            value={range}
                        />

                    </div>
                </div>
            )}

            <div className="block posts">
                {isLoading ?
                    <ProgressSpinner />
                    :
                    (posts?.map((post, index) => (
                        <Post
                            key={post.id}
                            postId={index}
                            bio={bio}
                            reported={props.reported}
                            verifyImg={verifImg}
                            isPictureFullscreen={isPictureFullscreen}
                            setIsPictureFullscreen={setIsPictureFullscreen}
                            setId={setId}
                            setId2={setId2}
                            // docId= {post.userID}
                            // currentID = {post.uid}
                            getRoomId={getRoomId}
                            showDropdown={showDropdown}
                            // getUserId={getUserId}
                            roomIdData={roomIdData}
                            showPopup={showPopup}
                            // setShowPopup={setShowPopup}
                            // chatData={chatData}
                            {...post}

                            reportedFilter={reportedFilter}

                            controls={
                                <Controls
                                    activePost={activePost}
                                    posts={posts}
                                    reported={reported}
                                    unread={unread}
                                    reasonPrompt={reasonPrompt}
                                    banPrompt={banPrompt}
                                    textPrompt={textPrompt}
                                    key={key}
                                    deleteForUnderage={deleteForUnderage}
                                    deleteforSexual={deleteforSexual}
                                    deleteForNudity={deleteForNudity}
                                    deleteForSelling={deleteForSelling}
                                    keepPost={() => { keepPost() }}
                                    displayBan={() => { displayBan() }}
                                    setReasonPrompt={setReasonPrompt}
                                    setTextPrompt={setTextPrompt}
                                    setBanPrompt={setBanPrompt}
                                    deleteBio={deleteBio}
                                    deleteUsername={deleteUsername}
                                    deleteVerificationImg={deleteVerificationImg}
                                    deletePost={deletePost}
                                    ban={ban}
                                    hide={hide}
                                    supreme={supreme}
                                    deleteUser={() => { deleteUser() }}
                                    docID={post.uid || post.id}
                                    handleSetCopy={() => handleSetCopy(post.id)}
                                    handleAge={handleAge}
                                />
                            }
                        />
                    )))
                }
            </div >

            {< PageNavigation />}
        </div>
    );


}

export default Chat;
