import { Card, CardActionArea, CardContent, CardMedia, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { selectActivePost } from "../../posts/postsSlice";
import PostCard from "./utils/PostCard";
import ActivePost from "./utils/ActivePost";
import { memo } from "react";

function AgePost(props) {
    const {
        id, post
    } = props;
    const activePost = useSelector(selectActivePost);
    const isActive = id === activePost;

    return (
        <>
            {
                isActive ?
                    <ActivePost id={id} post={post} />
                    :
                    <PostCard id={id} post={post} isActive={isActive} />
            }
        </>
    )
}

export default memo(AgePost);