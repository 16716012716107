import { FaBan } from "react-icons/fa";
import { GrClose } from "react-icons/gr";
import { GrCheckmark } from "react-icons/gr";
import { HiOutlineBan } from "react-icons/hi";


import {
    RiArrowGoBackLine,
    RiArrowRightLine,
    RiCheckDoubleFill,
    RiDeleteBin2Line,

} from "react-icons/ri";
import { ControlButton as Control } from "../components/ControlButton";
import { deletePostText } from "./functions/deletePostText";

interface Props {
    activePost: any;
    posts: any;
    reasonPrompt: any;
    banPrompt: any;
    textPrompt: any;
    activeKey: any;
    deleteForUnderage: any;
    deleteforSexual: any;
    deleteForNudity: any;
    deleteForSelling: any;
    keepPost: any;
    displayBan: any;
    setReasonPrompt: any;
    setTextPrompt: any;
    deleteBio: any;
    deleteUsername: any;
    deleteVerificationImg: any;
    deletePost: any;
    ban: any;
    hide: any;
    supreme: any;
    setBanPrompt: any;
    reported: boolean;
    unread: boolean;
    deleteUser: any;
    docID: any;
    handleSetCopy: any
    handleAge: any
}

export function Controls({
    activePost,
    posts,
    reasonPrompt,
    banPrompt,
    textPrompt,
    activeKey,
    deleteForUnderage,
    deleteforSexual,
    deleteForNudity,
    deleteForSelling,
    keepPost,
    displayBan,
    setReasonPrompt,
    setTextPrompt,
    setBanPrompt,
    deleteBio,
    deleteUsername,
    deleteVerificationImg,
    deletePost,
    ban,
    hide,
    supreme,
    reported,
    unread,
    deleteUser,
    docID,
    handleSetCopy,
    handleAge
}: Props): JSX.Element {


    return (
        <>
            <div
                className={
                    activePost === -1 ||
                        activePost >= posts.length ||
                        reasonPrompt ||
                        banPrompt ||
                        textPrompt
                        ? "controls hidden"
                        : "controls"
                }
            >
                {/* <Control
                    letter="a"
                    className="hide"
                    activeKey={activeKey}
                    description="Underage"
                    icon={<FaBan />}
                    onClick={deleteForUnderage}
                />
                <Control
                    letter="s"
                    className="hide"
                    activeKey={activeKey}
                    description="Sexual/ suggestive"
                    icon={<FaBan />}
                    onClick={deleteforSexual}
                />
                <Control
                    letter="d"
                    className="hide"
                    activeKey={activeKey}
                    description="Nudity"
                    icon={<FaBan />}
                    onClick={deleteForNudity}
                />
                <Control
                    letter="v"
                    className="hide"
                    activeKey={activeKey}
                    description="Selling/ Self-promotion"
                    icon={<FaBan />}
                    onClick={deleteForSelling}
                />
                <Control
                    letter="x"
                    className="hide"
                    activeKey={activeKey}
                    description="Against Guidelines"
                    icon={<FaBan />}
                    onClick={() =>
                        deletePost({
                            reason_report_int: 0,
                            reason_report: "Against guidelines",
                        })
                    }
                /> */}
                <Control
                    letter="f"
                    activeKey={activeKey}
                    className="profile-btn-show"
                    description="Notify"
                    // onClick={() => {
                    //     setReasonPrompt(true);
                    // }}
                    onClick={() => deleteUser()}
                    icon={<GrClose />}
                />
                <Control
                    letter="j"
                    activeKey={activeKey}
                    className="profile-btn-show"
                    onClick={() => {
                        keepPost(docID)
                    }}
                    description="Safe"
                    icon={<GrCheckmark />}
                />
                <Control
                    description="Ban"
                    letter="i"
                    className="profile-btn-show"
                    onClick={displayBan}
                    activeKey={activeKey}
                    icon={< HiOutlineBan />}
                />
                {
                    !posts[0].type &&
                    <Control
                        description="Selling"
                        letter="z"
                        className="profile-btn-show"
                        onClick={() => {
                            deleteUser();
                            deleteForSelling()
                            // console.log("USER IS SELLING")
                        }}
                        activeKey={activeKey}
                        icon={< HiOutlineBan />}
                    />
                }
                <Control
                    description="Age Verif"
                    letter="a"
                    className="profile-btn-show"
                    onClick={handleAge}
                    activeKey={activeKey}
                    icon={< HiOutlineBan />}
                />
                {/* <Control
                onClick={handleSetCopy}
                // letter="c"
                letter="q"
                activeKey={activeKey}
                description="Copy"
                icon={<FaBan />}
              /> */}
                {/* <Control
                    letter="i"
                    className="hide"
                    activeKey={activeKey}
                    description="Profile"
                    icon={<RiArrowRightLine />}
                    onClick={() => setTextPrompt(true)}
                /> */}
            </div>
            {textPrompt && activePost !== -1 && (
                <div className="controls">
                    <Control
                        letter="a"
                        activeKey={activeKey}
                        description="Return"
                        icon={<RiArrowGoBackLine />}
                        onClick={() => setTextPrompt(false)}
                    />
                    <Control
                        letter="1"
                        activeKey={activeKey}
                        description="Bio"
                        icon={<RiArrowRightLine />}
                        onClick={deleteBio}
                    />
                    <Control
                        letter="2"
                        activeKey={activeKey}
                        description="Username"
                        icon={<RiArrowRightLine />}
                        onClick={deleteUsername}
                    />
                    <Control
                        letter="3"
                        activeKey={activeKey}
                        description="Verification"
                        icon={<RiArrowRightLine />}
                        onClick={deleteVerificationImg}
                    />
                    <Control
                        letter="4"
                        activeKey={activeKey}
                        description="Caption"
                        icon={<RiArrowRightLine />}
                        onClick={() =>
                            deletePostText({
                                uid: String(activePost.uid),
                                collection: reported ? "moderation_post" : "public_post",
                                unread,
                                reported,
                            })
                        }
                    />
                </div>
            )}
            <div
                className={
                    activePost === -1 || activePost >= posts.length || !reasonPrompt
                        ? "controls hidden"
                        : "controls"
                }
            >
                <Control
                    letter="a"
                    activeKey={activeKey}
                    description="Back"
                    icon={<RiArrowGoBackLine />}
                    onClick={() => setReasonPrompt(false)}
                />
                <Control
                    letter="1"
                    activeKey={activeKey}
                    description="Nudity / Partial"
                    icon={<FaBan />}
                    onClick={deleteForNudity}
                />
                <Control
                    letter="2"
                    activeKey={activeKey}
                    description="Underage"
                    icon={<FaBan />}
                    onClick={deleteForUnderage}
                />
                <Control
                    letter="3"
                    activeKey={activeKey}
                    description="Selling / Self-Promotion"
                    icon={<FaBan />}
                    onClick={deleteForSelling}
                />
                <Control
                    letter="4"
                    activeKey={activeKey}
                    description="Suggestive / Sexual Suggestion"
                    icon={<FaBan />}
                    onClick={deleteforSexual}
                />
                <Control
                    letter="5"
                    activeKey={activeKey}
                    description="Scam"
                    icon={<FaBan />}
                    onClick={() =>
                        deletePost({
                            reason_report_int: 5,
                            reason_report: "Scam",
                        })
                    }
                />
                <Control
                    letter="6"
                    activeKey={activeKey}
                    description="Illegal"
                    icon={<FaBan />}
                    onClick={() =>
                        deletePost({
                            reason_report_int: 6,
                            reason_report: "Illegal",
                        })
                    }
                />
                <Control
                    letter="0"
                    activeKey={activeKey}
                    description="Against Guidelines"
                    icon={<FaBan />}
                    onClick={() =>
                        deletePost({
                            reason_report_int: 0,
                            reason_report: "Against guidelines",
                        })
                    }
                />
            </div>
            <div className={!banPrompt ? "controls hidden" : "controls"}>
                <Control
                    letter="a"
                    className="profile-btn-show"
                    activeKey={activeKey}
                    description="Return"
                    icon={<RiArrowGoBackLine />}
                    onClick={() => setBanPrompt(false)}

                />
                <Control
                    letter="1"
                    className="profile-btn-show"
                    activeKey={activeKey}
                    description="Banned"
                    // icon={<FaBan />}
                    icon={< HiOutlineBan />}
                    onClick={ban}
                />
                <Control
                    letter="2"
                    className="profile-btn-show"
                    activeKey={activeKey}
                    description="Hide"
                    // icon={<FaBan />}
                    icon={< HiOutlineBan />}
                    onClick={hide}
                />
                <Control
                    letter="3"
                    className="profile-btn-show"
                    activeKey={activeKey}
                    description="Supreme"
                    // icon={<FaBan />}
                    icon={< HiOutlineBan />}
                    onClick={supreme}
                />
            </div>
        </>
    );
}
