import firebase from "firebase";
import "firebase/firestore";

import store from "./store";

import { removePost } from "./../features/posts/postsSlice";

import { firebaseConfig, reCaptchaSiteKey } from "../config";

firebase.initializeApp(firebaseConfig);

// var myTimestamp = firebase.firestore.Timestamp.fromDate(new Date());

// export let convertToTimestamp = (date) => {
//   var myTimestamp = firebase.firestore.Timestamp.fromDate(date);
//   console.log("myTimestamp", myTimestamp);
//   return myTimestamp;
// };

// const appCheck = firebase.appCheck();

firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION);

export let db = firebase.firestore();
export const { Timestamp } = firebase.firestore;
// var settings = { timestampsInSnapshots: true }; // force Timestamp instead of Date
// db.settings(settings);
export let storage = firebase.app().storage("gs://addchat_public_posts/");

var database = firebase.database().ref("list_reports");
// console.log(database, "-----------Database------------------");

// firebase.functions().useEmulator("localhost", 5001);
// firebase.auth().useEmulator("http://localhost:9099");

// db.useEmulator("localhost", 8080);

export const getTime = firebase
  .functions()
  .httpsCallable("getTime");

export const move_pic_to_hist = firebase
  .functions()
  .httpsCallable("move_pic_to_hist");

export const age_verif_mod = firebase
  .functions()
  .httpsCallable("age_verif_mod");

export const get_userid_mod = firebase
  .functions()
  .httpsCallable("get_userid_mod");

export const logAnalytics = firebase.functions().httpsCallable("logAnalytics");
export const getUserByID = firebase.functions().httpsCallable("getUserByID");
export const addModeratorLocally = firebase
  .functions()
  .httpsCallable("addModeratorLocally");
export const sendNotification = firebase
  .functions()
  .httpsCallable("sendNotification");

export const ban_user_mod = firebase
  .functions()
  .httpsCallable("ban_user_mod");

export const sendResultVerification = firebase
  .functions()
  .httpsCallable("sendResultVerification");
export const profile_moderation_response = firebase
  .functions()
  .httpsCallable("profile_moderation_response");
export const bio_moderation = firebase
  .functions()
  .httpsCallable("bio_moderation");
export const chat_report_mod = firebase
  .functions()
  .httpsCallable("chat_report_mod");
export const google_translate_moderation = firebase
  .functions()
  .httpsCallable("google_translate_moderation");
export const profile_pic_moderation = firebase
  .functions()
  .httpsCallable("profile_pic_moderation");
export const username_moderation = firebase
  .functions()
  .httpsCallable("username_moderation");
export const delete_verification_moderation = firebase
  .functions()
  .httpsCallable("delete_verification_moderation");
export const view_verif_img = firebase
  .functions()
  .httpsCallable("view_verif_img");
export const request_verif_moderation = firebase
  .functions()
  .httpsCallable("request_verif_moderation");
export const last_post_moderation = firebase
  .functions()
  .httpsCallable("last_post_moderation");
export const caption_delete_moderation = firebase
  .functions()
  .httpsCallable("caption_delete_moderation");
export const explain_text_mod = firebase
  .functions()
  .httpsCallable("explain_text_mod");
export const res_chat_report_swiping = firebase
  .functions()
  .httpsCallable("res_chat_report_swiping");
export const get_counter_mod = firebase
  .functions()
  .httpsCallable("get_counter_mod");

export const res_profile_report_mod_swiping = firebase
  .functions()
  .httpsCallable("res_profile_report_mod_swiping");

export const firestore = firebase.firestore;
export const auth = firebase.auth;

export function getState() {
  var getPhoneState = firebase.functions().httpsCallable("deviceChecker");
  getPhoneState();
}

export const getBannedInfo = function (uid) {
  return db
    .collection("banned_moderation")
    .doc(uid)
    .get()
    .then((banInfo) => {
      return banInfo;
    })
    .catch(() => {
      return "Error loading banned info.";
    });
};

export function firebaseDeletePost(id, uid, reported) {
  db.collection("banned_moderation")
    .doc(uid)
    .set(
      { report_counter: firebase.firestore.FieldValue.increment(1) },
      { merge: true }
    )
    .catch((err) => {
      console.err(err);
    });

  db.collection("public_post")
    .doc(id)
    .delete()
    .then(()=>{
      console.log("it was called")
    })
    .catch((err) => {
      console.err(err);
    });

  db.collection("moderation_post")
    .doc(id)
    .delete()
    .catch((err) => {
      console.err(err);
    });

  store.dispatch(removePost({ id }));
}
