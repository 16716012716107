import "./verification.css";

import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { setActivePost, selectActivePost } from "../posts/postsSlice";
dayjs.extend(relativeTime);

export function VerificationSwipingHistoryPost(props) {
  
    let activePost = useSelector(selectActivePost);
    let dispatch = useDispatch();
  // console.log(activePost,"-inverification--active post----",activePost)
    // useEffect(() => {}, [props]);
  
    // if (activePost !== -1) {
      
      if (activePost == props.postId) {
        console.log(props,"================props========Swiping============");
        props.setId(props.uid);
        props.setUserName(props.name);
        props.setLastPost(props.last_post_url);
        props.setProfileImage(props.imgOne);
      }
    // }
   
  
    return (
      <div className={activePost == props.postId ? "active" : ""}>
        <div
          className="post"
          onClick={() => {
            dispatch(setActivePost(props.postId));
          }}
        >
          <img className="thumbnail" src={props.imgOne} />
        </div>
  
        <div className="fullscreen" style={{backgroundColor: props.result === 1 ? "#90ee908f" : props.result === 2 ? "#ee90908f" : "#eb57eb87"}}>
          <div
            onClick={() => dispatch(setActivePost(-1))}
            className="hidden close"
          >
            Close
          </div>
  
          {/* <div className="demo">
            <div >
              <h3 style={{backgroundColor: "#ffffff",margin: "0",padding: "15px 0"}}> profile Information </h3>
                <div className="fields">
                    {profileInfoFields({profileInfo:props})}
                </div>
            </div>                
          </div>  */}
  
          <div className="images" >
            <div>
              {
                props?.app != "addchat" ?  <img
                style={{width:"100%",objectFit:"contain"}}
                className="ref picture"
                src="https://firebasestorage.googleapis.com/v0/b/swiping-6aeb7.appspot.com/o/CAESIBOwPgKOd2GS%2B3POwj%2BZowDDjsTVQmMLtWkIuH7m4DZ%2FverifPic%2F4BC37E29-7716-4998-9814-71013D559E25.jpg?alt=media&token=11a11b0a-8733-48b6-b725-9fbf184c8d6f"
                /> : <img
                  style={{width:"100%",objectFit:"contain"}}
                  className="ref picture"
                  src={props?.last_post_url}
                /> 
              }
            </div>
  
            <div>
              <img className="hidden picture" src={props.imgVerif} style={{width:"100%",objectFit:"contain"}}/>
            </div>

            <div>
            {
              props?.version >= 3 ? <img
                style={{width:"100%",objectFit:"contain"}}
                className="ref picture"
                src={props?.imgSign}
              /> : ""
            }
          </div>

            <div>
              <img className="picture" src={props.imgOne}  style={{width:"100%",objectFit:"contain"}} />
            </div>
          </div>
  
          <div className="info">
            <div style={{position:"relative"}}>
              <span> • {props.age} yo </span>
              <span> • {props.gender ? "F" : "M"} </span>
              <span> • {props.country} </span>
              <span> • {props.name} </span>
              <span>• count: {props.submission_counter} </span>  
            {/* <span>• {dayjs.unix(props.date.seconds).fromNow()} </span> */}
            
            {/* {
              props.result === 1 ? <div> <img src="./accepted.png" width="25px" style={{top: "0vw", position: "absolute", right: "-3vw",width: "2vw",top: "-0.3vw"}} /> </div> : props.result === 2 ? <div> <img src="./rejected.png" width="25px" style={{top: "0vw", position: "absolute", right: "-3vw",width: "2vw",top: "-0.3vw"}} /> </div> :<div> <img src="./banned.png" width="25px" style={{top: "0vw", position: "absolute", right: "-3vw",width: "2vw",top: "-0.3vw"}} /> </div> 
            } */}
            </div>
          </div>
          
          
          <div className="black-input">
          {/* <input value={props.by_mod}/> */}
          <p>{props.by_mod}</p>
          </div>
          
        </div>
      </div>
    );
  };
