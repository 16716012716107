import { db, firestore } from "../../../../app/firebase";

interface Props {
  docID: string;
  last_reason?: string;
}

export function writeRestoreBannedModeration({ docID, last_reason }: Props) {
  db.collection("banned_moderation")
    .doc(docID)
    .set(
      {
        reason_report_int: 0,
        reason_report: "Post Restored",
        banned_unlock_date: firestore.FieldValue.serverTimestamp(),
        ...(last_reason !== "mistake"
          ? {
              report_counter_mod: firestore.FieldValue.increment(-1),
            }
          : {}),
      },
      { merge: true }
    )
    .then(() => {
      console.log("Successfully written at banned moderation");
    });
}
