import { v4 as uuidv4 } from "uuid";
import { db } from "../../../../app/firebase";

export function sendRestoreMessage({ uid, username, emoji }) {
  let admin_uid = uuidv4();

  let usersData = {
    useraid: admin_uid,
    userbid: uid,

    userausername: "Addchat Team",
    userbusername: username,

    emojia: "⚠️",
    emojib: emoji,
  };

  if (admin_uid < uid) {
    usersData = {
      useraid: uid,
      userbid: admin_uid,

      userausername: username,
      userbusername: "Addchat Team",

      emojia: emoji,
      emojib: "⚠️",
    };
  }

  let message = {
    ...usersData,
    message: "Your Post is available again!",
    type: "text",
    status: "Sent",
    senderID: admin_uid,
    date: Date.now() / 1000,
  };
  const chatName = admin_uid > uid ? admin_uid + uid : uid + admin_uid;

  db.collection("recent_chat")
    .doc(uid)
    .collection("recent_list")
    .doc(chatName)
    .set(message, { merge: true })
    .then(() =>
      console.log(
        "Restore chat success",
        message,
        "recent_chat/" + uid + "/recent_list/" + chatName
      )
    )
    .catch((error) => console.log("Restore recent chat error", error));

  const chat = {
    userID: admin_uid,
    text: "Sorry we deleted your post by mistake, it’s back live :) Have fun, Addchat Team",
    status: "Sent",
    type: "text",
    date: Date.now() / 1000,
  };

  db.collection("chat")
    .doc(chatName)
    .collection("discussion")
    .add(chat)
    .catch((error) => console.log("Restore chat error", error));

  console.log("chatName", chatName);
}
