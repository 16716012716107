import { ageFlag, resToString } from './utils';
import { useSelector } from 'react-redux';
import { selectActivePost } from '../../../posts/postsSlice';

export default function TableDetails({ age_ai, age_req, liveliness, mod_res }) {
  // const [ageDiff, setAgeDiff] = useState(ageFlag(age_req, age_ai));
  let activePost = useSelector(selectActivePost);
  // useEffect(()=>{
  //   setAgeDiff(ageFlag(age_req, age_ai))
  // }, [activePost])
  
  return (
    <div>
      <span style={{
        fontSize: '30px',
        backgroundColor: 'white',
        padding: '5px 20px',
        borderRadius: '10px',
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.15)',
      }}>
        {mod_res && <span style={{ fontWeight: '500' }}>{resToString(mod_res)} • </span> }
        AI: <span style={{ fontWeight: '500' }}>{age_ai}</span> 
        {/* <>
          {ageDiff[0] && '🔴'} Diff: <span style={{ fontWeight: '500' }}>{ageDiff[1]}</span> •
        </> */}
        <span> • </span>Liveliness: <span style={{ fontWeight: '500' }}>
          {liveliness === "fake" ? `${liveliness} 🔴` : liveliness}
        </span>
      </span>
    </div>
  );
}
