import { db, sendNotification } from "../../app/firebase";
import store from "../../app/store";
import { setPosts, changePage } from "./postsSlice";


function logError(error) {
    console.error(error);
}

export async function getPosts({
    // reported = false,
    // unread = false,
    filter = false,
    page = 0,
    startAt = false,
    startAfter = false,
    chatHistory = false,
    // chatHistory_uid,
    uid,
    mod_res,
    userID,
    firstFetch = false,

}) {
    console.log("getposts:", "chatHistory", chatHistory, "userID", userID);
    let limit = 20;
    // let reported1 = reported;
    // let unread1 = unread;
    let page1 = page;
    let startAt1 = startAt;
    let startAfter1 = startAfter;

    // console.log(
    //     // reported,
    //     // unread,
    //     page,
    //     filter,
    //     startAt,
    //     startAfter, uid, chatHistory, "perameter----next")

    let postsRef;

    if (window.location.pathname === '/swiping/chat' && filter && mod_res) {

        postsRef = db
            .collection('swiping')
            .where('type', '==', 'chat_report')
            .where('waiting_moderation', '==', false)
            .where("mod_res", "==", mod_res)
            .orderBy("mod_res_date", "desc")
            .limit(20);

    }

    else if (window.location.pathname === '/swiping/chat' && filter && uid) {

        postsRef = db
            .collection('swiping')
            .where('type', '==', 'chat_report')
            .where('waiting_moderation', '==', false)
            .where("mod_uid", "==", uid)
            .orderBy("mod_res_date", "desc")
            .limit(20);

    }
    else if (filter && uid) {
        postsRef = db.collection("chat_history").where("mod_uid", "==", uid).orderBy('date', 'desc').limit(20);
        console.log(userID, "getposts - filter && uid");
    }
    else if (window.location.pathname === '/swiping/chat' && chatHistory) {

        postsRef = db
            .collection('swiping')
            .where('type', '==', 'chat_report')
            .where('waiting_moderation', '==', false)
            .limit(21);
    }
    else if (chatHistory && userID) {
        postsRef = db.collection("chat_history").where("reportedUser.userID", "==", userID).orderBy('date', 'desc').limit(20);
        console.log(userID, "getposts - chatHistory && userID");
    }
    else if (chatHistory) {
        postsRef = db.collection("chat_history").orderBy('date', 'desc').limit(20);
        console.log(userID, "getposts - chatHistory");
    }
    else if (window.location.pathname === '/swiping/chat') {
        postsRef = db
            .collection('swiping')
            .where('type', '==', 'chat_report')
            .where('waiting_moderation', '==', true)
            .limit(21);
    }

    else {
        postsRef = db.collection("chat_report").where("open_report_counter", ">=", 1)
            .orderBy("open_report_counter", "desc").orderBy("last_report_date", "desc").limit(20);
        console.log(userID, "getposts - else");
    }


    // postsRef.get().then((snapshot) => {
    //     console.log(snapshot.docs, "==============================");
    // });

    if (startAfter) {
        postsRef = postsRef.startAfter(startAfter);
    } else if (startAt) {
        postsRef = postsRef.startAt(startAt);
    }

    // ---------------------------------------------------------
    // console.log(postsRef, "ref og post-----")

    return postsRef
        .get()
        .then((snapshot) => {
            // console.log(snapshot, "snapshot detail", snapshot.docs.length)
            let posts = [];
            if (snapshot.docs.length === 0) {
                console.log("caall return ---")
                let data = {
                    posts,
                    // reported, unread,
                    page, filter, chatHistory
                }
                console.log(data, "finel--- data---nextt");
                store.dispatch(setPosts(data));
                return { lastSeen: {}, firstSeen: {}, err: "error" };
            }
            // console.log(snapshot, "------------------------------Snapshot---------------------");

            snapshot.forEach((item, i) => {

                let post = { ...item.data(), id: item.id };
                //  console.log(post, "------------------------------Snapshot---------------------");
                post.datePost = post.date?.seconds;
                // post.response_time = post.response_time?.seconds;
                // post.seen_mod_date = post.seen_mod_date?.seconds;
                post.imageUrlPost = post.profile_img
                post.date = undefined
                post.profile_img = undefined
                post.profile_data = true
                if (post.seen_mod_date === undefined) delete post.seen_mod_date;
                posts.push(post);
            });

            // console.log(posts, "posts chat component");

            let lastVisible = snapshot.docs[snapshot.docs.length - 1];
            let data = {
                posts,
                // reported, unread,
                page, filter, chatHistory, firstFetch
            }
            console.log("Chat Data", data.posts);
            store.dispatch(setPosts(data));
            if (posts.length){
                store.dispatch(changePage(page));
                console.log("page", page)
            } 

            return { lastSeen: lastVisible, firstSeen: snapshot.docs[0] };
        })
        .catch((err) => {
            console.error("Error with getting posts from firebase:", err);
            return { lastSeen: {}, firstSeen: {}, err: "error" };
        });
}

export function deleteNotification({ fcm_token }) {
    sendNotification({ registrationToken: fcm_token, delete: true })
        .then()
        .catch(logError);
}
export function keepNotification({ fcm_token }) {
    sendNotification({ registrationToken: fcm_token, keep: true })
        .then()
        .catch(logError);
}
