import { useSelector } from "react-redux";
import { db, sendNotification } from "../../app/firebase";
import store from "../../app/store";
import { setPosts, changePage, setActivePost, selectActivePost } from "./postsSlice";

function logError(error) {
  console.error(error);
}

export async function getPosts({
  reported = false,
  unread = false,
  page = 0,
  startAt = false,
  startAfter = false,
  // endBefore = false,////////////
  gender,
  activePost = -1
}) {
  console.log(`Fetching with unread = ${unread} and reported= ${reported}`)
  let limit = 20;
  let postsRef = unread
    ? db
      .collection("public_post")
      .where("post_seen_mod", "==", false)
      .where("finish_processing", "==", true)
      .orderBy("datePost", "desc")
      .limit(limit)
    : reported
      ? db
        .collection("moderation_post")
        .where("moderated", "==", false)
        .orderBy("datePost", "desc")
        .limit(limit)
      : db
        .collection("public_post")
        .where("finish_processing", "==", true)
        .orderBy("datePost", "desc")
        .limit(limit);

  if (!unread && !reported) {
    if (gender === 0 || gender === 1)
      postsRef = postsRef.where("gender", "==", gender);
    // console.log("gender", gender);
  }
  // console.log("postsRef", postsRef);
  if (startAfter) postsRef = postsRef.startAfter(startAfter);
  if (startAt) postsRef = postsRef.startAt(startAt);
  // if (endBefore) postsRef = postsRef.endBefore(endBefore);  /////////////

  return postsRef
    .get()
    .then((snapshot) => {
      let posts = [];
      if (snapshot.docs.length === 0) {
        console.log("empty data")
        return { lastSeen: {}, firstSeen: {}, err: "error", noData: true };
      }

      snapshot.forEach((item, i) => {
        let post = { ...item.data(), id: item.id };
        post.datePost = post.datePost?.seconds;
        post.response_time = post.response_time?.seconds;
        post.seen_mod_date = post.seen_mod_date?.seconds;
        if (post.seen_mod_date === undefined) delete post.seen_mod_date;
        posts.push(post);
      });

      let lastVisible = snapshot.docs[snapshot.docs.length - 1];
      // let data = {
      //   posts, reported, unread, page
      // }
      let data = {
        posts, reported, unread, page
      }
      // console.log(data, "-=================");
      store.dispatch(setPosts(data));
      if (posts.length){
        store.dispatch(changePage(page));
        if(page > 1 && activePost !== -1) store.dispatch(setActivePost(0));
        // console.log("these are the posts", data);
      } 

      return { lastSeen: lastVisible, firstSeen: snapshot.docs[0] };
    })
    .catch((err) => {
      console.error("Error with getting posts from firebase:", err);
      return { lastSeen: {}, firstSeen: {}, err: "error" };
    });
}

export function deleteNotification({ fcm_token }) {
  sendNotification({ registrationToken: fcm_token, delete: true })
    .then()
    .catch(logError);
}
export function keepNotification({ fcm_token }) {
  sendNotification({ registrationToken: fcm_token, keep: true })
    .then()
    .catch(logError);
}
