import { db } from "../../../app/firebase";
import { firestore } from "../../../app/firebase";
import { parseUrl, removeUndefined } from "../../../helpers/helpers";

export function deleteHistory({
    post,
    user = { uid: "none" },
    reason,
    location = "not-specified",
    action = "delete",
}) {
    const imageID = parseUrl(post.imageUrlPost);

    if(reason.reason_report === 'delete'){
        return alert("reason can not be delete");
      }
    db.collection("history")
        .doc(imageID ?? user.uid)
        .set(
            {
                post_data: removeUndefined(post),
                mods: firestore.FieldValue.arrayUnion(user.uid),
                action,
                edit_counter: firestore.FieldValue.increment(1),
                mod_uid: user.uid,
                ...(!!reason
                    ? {
                        reason: reason.reason_report,
                        reason_int: reason.reason_report_int,
                    }
                    : {}),
                location,
                date: firestore.FieldValue.serverTimestamp(),
                imageID: imageID,
            },
            { merge: true }
        );
}
