import { db, firestore } from "../../../app/firebase";
import { parseUrl, removeUndefined } from "../../../helpers/helpers";

type ActionLocation = "all_posts" | "all_reported" | "new_posts";

interface Props {
    post: any;
    user: { uid: any };
    reason?: { reason_report: any; reason_report_int: any };
    location: ActionLocation;
}

export function keepHistory({
    post,
    user,
    reason = {
        reason_report: "keep",
        reason_report_int: 9,
    },
    location,
}: Props) {
    const imageID = parseUrl(post.imageUrlPost);
    
    db.collection("history")
        .doc(imageID ?? user.uid)
        .set(
            {
                post_data: removeUndefined(post),
                mods: firestore.FieldValue.arrayUnion(user.uid),
                action: "keep",
                edit_counter: firestore.FieldValue.increment(1),
                mod_uid: user.uid,
                ...(!!reason
                    ? {
                        reason: reason.reason_report,
                        reason_int: reason.reason_report_int,
                    }
                    : {}),
                location,
                date: firestore.FieldValue.serverTimestamp(),
                imageID: imageID,
            },
            { merge: true }
        );
}
