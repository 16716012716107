import { configureStore } from "@reduxjs/toolkit";
import userReducer from "../features/auth/userSlice.ts";
import postsReducer from "../features/posts/postsSlice";
import profileReducer from "../features/profile/postsSlice.js";

export default configureStore({
  reducer: {
    user: userReducer,
    posts: postsReducer,
    profile: profileReducer,
  },
});
