import dayjs from "dayjs";

interface Props {
  banData: any;
}

export function BanDataFields({ banData }: Props) {
  let keys = Object?.keys(banData ?? {}).sort();

  return keys?.map(function (field): JSX.Element {
    let value = banData?.[field];

    if (typeof value === "boolean") value = JSON.stringify(value);
    if (typeof value === "object") {
      value = dayjs.unix(value?.seconds).fromNow();
    }

    if (field === "supreme") return <div key={field + "bandata"}></div>;
    if (field === "imageUrlPost") return <div key={field + "bandata"}></div>;

    return (
      <div key={field + "bandata"} className="field">
        <div className="label">{field}</div>
        <div className="value">{value}</div>
      </div>
    );
  });
}

export function lastPostFields({ lastPost }: { lastPost: object }) {
  let keys = Object?.keys(lastPost) ?? {};

  keys = keys?.sort();

  return keys?.map((field) => {
    let value: any = lastPost[field as keyof object];

    if (typeof value === "boolean") value = JSON.stringify(value);
    if (typeof value === "object") {
      if (value?.seconds) {
        value = dayjs.unix(value?.seconds).fromNow();
      } else {
        value = JSON.stringify(value);
      }
    }
    return (
      <div className="field">
        <div className="label">{field}</div>
        <div className="value">{value}</div>
      </div>
    );
  });
}

export function userInfoFields({ userInfo }: { userInfo: object }) {

  console.log(userInfo,"==========User Info=============");
  
  const keys = Object.keys(userInfo ?? {});
  const index = keys?.indexOf('username');
if (index > -1) { // only splice array when item is found
  keys.splice(index, 1); // 2nd parameter means remove one item only
}
  // const newKeys = keys.indexOf('username')
  keys?.unshift('username')
  // console.log(newKeys,"==========newKeys=============");
  

//



const fromIndex = keys?.indexOf("bio"); // 👉️ 0
const toIndex = 1;
if (fromIndex > -1) {
const element = keys?.splice(fromIndex, 1)[0];
console.log(element); 

keys?.splice(toIndex, 0, element);
}
console.log(keys,"==========keys=============");

  


  return keys?.map((field) => {
    let value: any = userInfo?.[field as keyof object];

    if (typeof value === "boolean") value = JSON.stringify(value);
    if (typeof value === "object") {
      if (value?.seconds) {
        value = dayjs.unix(value?.seconds).fromNow();
      } else {
        value = JSON.stringify(value);
      }
    }
    // if (field === "bio") return false;
    return (
      <div key={field + "userinfo"} className="field">
        <div className="label">{field}</div>
        <div className="value">{value}</div>
      </div>
    );
  });
}

export function profileInfoFields({ profileInfo }: { profileInfo: object }) {
  console.log("first")
  const keys = Object.keys(profileInfo ?? {}).sort();

  return keys?.map((field) => {
    let value: any = profileInfo?.[field as keyof object];

    if (typeof value === "boolean") value = JSON.stringify(value);

    if (typeof value === "object") {
      console.log("value",value)
      if (value?.seconds) {
        value = dayjs.unix(value?.seconds).fromNow();
      } else {
        value = JSON.stringify(value);
      }
    }
    // if (field === "bio") return false;
    return (
      <div key={field + "userinfo"} className="field">
        <div className="label">{field}</div>
        <div className="value">{value}</div>
      </div>
    );
  });
}
