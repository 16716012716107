import { createSlice } from "@reduxjs/toolkit";

export const postsSlice = createSlice({
  name: "posts",

  initialState: {
    pressedKey: 0,
    all: [],
    reported: [],
    unread: [],
    verification: [],
    loading: true,
    activePost: -1,
    page: 1,
    lastSeen: false,
    uuid: '',
    isCleared: false,
    startAfter: false,
    fetchNext: false
  },
  reducers: {
    setPosts: (state, action) => {
      console.log("this is the store setting posts", action.payload)
      // console.log('store:', state)
      if (action.payload.page === -1) {
        state.all = []
        state.page = 1
        state.isCleared = true
      }

      else {
        state.all[action.payload.page] = action.payload.posts;
        state.isCleared = false;
      }

      // if (action.payload.unread)
      //   state.unread[action.payload.page] = action.payload.posts;
      // else if (action.payload.reported)
      //   state.reported[action.payload.page] = action.payload.posts;
      // else state.all[action.payload.page] = action.payload.posts;
      // state.loading = false;
    },
    clearPosts: (state, action) => {
      state.all = [];
      state.page = 1;
      state.isCleared = true;
      // state.reported = [];
      // state.unread = [];
      state.activePost = -1;
      console.log('clearing the posts')
    },
    setIsCleared: (state, action) => {
      state.isCleared = action.payload
    },
    setUuid: (state, action) => {
      state.uuid = action.payload
      console.log('setting uuid', state.uuid)
    },
    setActivePost: (state, action) => {
      if (action.payload >= -1) {
        state.activePost = action.payload;
        console.log("this is the store setting active post", state.activePost)
      }
    },
    removePost: (state, action) => {
      let id = action.payload.id;

      if (state.reported[state.page])
        state.reported[state.page].forEach((post, i) => {
          if (post.id === action.payload.id)
            state.reported[state.page].splice(i, 1);
        });

      if (state.all[state.page])
        state.all[state.page].forEach((post, i) => {
          if (post.id === action.payload.id) state.all[state.page].splice(i, 1);
        });

      if (state.unread[state.page]) {
        console.log('this is state.unread[state.page]', state.unread[state.page])
        state.unread[state.page].forEach((post, i) => {
          if (post.id === action.payload.id)
            state.unread[state.page].splice(i, 1);
        });
      }
    },
    removePost2: (state, action) => {
      let id = action.payload.id;
      if (state.all[state.page]) {
        state.all[state.page].forEach((post, i) => {
          if (i === action.payload.id) state.all[state.page].splice(i, 1);
        });
        console.log(`Removing post ${id} from ${JSON.stringify(state.all[state.page][0])}`)
      }
    },
    removePostSwiping: (state, action) => {
      if (state.all[state.page])
        state.all[state.page].forEach((post, i) => {
          if (post.uid === action.payload.id) state.all[state.page].splice(i, 1);
        });
    },
    setPostUsername: (state, action) => {
      // console.log(action.payload,"------payload",state.page,state.activePost);
      // console.log(state?.all[state.page][state.activePost],"---------")
      // console.log(state.all[state.page][state.activePost].username)

      state.all[state.page][state.activePost].username = action.payload.username;

      // ************* those are when we had three arrays
      // if (action.payload.unread) {
      //   state.unread[state.page][state.activePost].username =
      //     action.payload.username;
      // } else if (action.payload.reported) {
      //   state.reported[state.page][state.activePost].username =
      //     action.payload.username;
      // } else {
      //   state.all[state.page][state.activePost].username =
      //     action.payload.username;
      // }
    },
    setPostText: (state, action) => {

      state.all[action.payload.page][action.payload.activePost].postText = action.payload.postText;
      // ************* those are when we had three arrays
      // if (action.payload.unread) {
      //   state.unread[state.page][state.activePost].postText =
      //     action.payload.postText;
      // } else if (action.payload.reported) {
      //   state.reported[state.page][state.activePost].postText =
      //     action.payload.postText;
      // } else {
      //   state.all[state.page][state.activePost].postText =
      //     action.payload.postText;
      // }     
    },
    setBioText: (state, action) => {
      state.all[state.page][state.activePost].bio = action.payload.bio;
    },
    nextPost: (state) => {
      state.activePost++;
    },
    changePage: (state, action) => {
      state.page = action.payload;
    },
    setStartAfter: (state, action) => {
      state.startAfter = action.payload;
    },
    setFetchNext: (state, action) => {
      state.fetchNext = action.payload;
    }
  },
});

export const {
  keyDown,
  updatePosts,
  setActivePost,
  nextPost,
  setPosts,
  clearPosts,
  setIsCleared,
  removePost,
  removePost2,
  removePostSwiping,
  changePage,
  setLastSeen,
  setPostUsername,
  setPostText,
  setBioText,
  setUuid,
  setStartAfter,
  setFetchNext
} = postsSlice.actions;

export const selectPosts = (state) => state.posts.all;
export const selectReportedPosts = (state) => state.posts.reported;
export const selectUnreadPosts = (state) => state.posts.unread;
export const selectUuid = (state) => state.posts.uuid;
export const selectIsCleared = (state) => state.posts.isCleared;

export const selectPage = (state) => state.posts.page;
export const selectLastSeen = (state) => state.posts.lastSeen;

export const selectVerificationPosts = (state) => state?.posts?.verification;

export const selectPostsLoading = (state) => state?.posts?.loading;
export const selectActivePost = (state) => state?.posts?.activePost;
export const selectStartAfter = (state) => state?.posts?.startAfter;
export const selectFetchNext = (state) => state?.posts?.fetchNext;


export default postsSlice.reducer;
