import "./auth.scss";
import { InputText } from "primereact/inputtext";
import { Card } from "primereact/card";
import { Button } from "primereact/button";
import { useDispatch } from "react-redux";

import { useEffect, useRef, useState } from "react";
import { setUser } from "./userSlice";
import { auth } from "./../../app/firebase";
import amplitudeLog from "../../app/logs/amplitudeLog";
import { ProgressSpinner } from "primereact/progressspinner";

// import ReCAPTCHA from "react-google-recaptcha"


const SITE_KEY = "6Le19UwfAAAAAPCw_yTP0aStazCh5-2bJ26Fleoq"
// const SITE_KEY = "6LeJnkYfAAAAANh7iAIZ_oBNba5Ogj_mXFGhK2az"
const SECERET_KEY = "6Le19UwfAAAAAMhCNQ39p0vb2SyBN1vSDfXfqXEr"

function SignInComponent() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const [token, setToken] = useState("")
  const reCaptcha = useRef();

  const dispatch = useDispatch();

  const ref = useRef()

  useEffect(() => {
    auth().onAuthStateChanged(async (user) => {
      setIsLoading(false);
      // setError(
      //           "You are not a moderator. Please contact the application staff to get moderator permisions."
      //         );
    });
  }, [dispatch]);

  useEffect(() => {

    document.addEventListener('keydown', callback)

    return () => {
      document.removeEventListener('keydown', callback)
    }
  }, [])

  function callback(e) {
    if (e.key == 'Enter') {
      // console.log("first")
      // login(e)
      ref.current.click()
      e?.preventDefault()
    }
  }

  async function login(e) {
    // const token = await reCaptcha.current.executeAsync();
    // reCaptcha.current.reset();
    // console.log(token);

    // if (!token) {
    //   console.log("You must verify captcha");
    //   return;
    // }

    // fetch(`https://recaptcha.google.com/recaptcha/api/siteverify?secret=6Le19UwfAAAAAMhCNQ39p0vb2SyBN1vSDfXfqXEr&response=${token}`, {
    //   method: "POST",
    //   mode: 'no-cors',
    // }).then((data) => {

    setIsLoading(true);
    loginUser(email, password, token);
    // console.log(v3Data);

    // }).catch((err) => {
    //   console.log(err);
    // })

    e?.preventDefault();
  }

  // function loginUser(email, password, token) {
  //   setError(null);

  //   auth()
  //     .signInWithEmailAndPassword(email, password)
  //     .then((userCredential) => {
  //       let user = userCredential.user;

  //       amplitudeLog({
  //         data: { userID: user.uid, email },
  //         event: "login_webapp",
  //       });
  //     })
  //     .catch((error) => {
  //       console.error("Login failed:", error);
  //       setError("Login failed.");
  //       setIsLoading(false);
  //     });
  // }

  async function getIpAddress() {
    try {
        const response = await fetch('https://api.ipify.org?format=json');
        const data = await response.json();
        return data.ip;
    } catch (error) {
        console.error('Error fetching IP:', error);
        return null;
    }
};

function getOrCreateUID() {
  let uid = localStorage.getItem('userDeviceUID');
  if (!uid) {
      uid = generateRandomUID();
      localStorage.setItem('userDeviceUID', uid);
  }
  return uid;
}

function generateRandomUID() {
  // Generate a UID. This can be more complex based on your needs.
  return Math.random().toString(36).substring(2) + Date.now().toString(36);
}

async function loginUser(email, password, token) {
  setError(null);

  const device_id = getOrCreateUID();

  const screenWidth = window.screen.width;
  const screenHeight = window.screen.height;

  const screenSize = `L${screenWidth}xH${screenHeight}`
  
  auth()
      .signInWithEmailAndPassword(email, password)
      .then(async (userCredential) => {
          let user = userCredential.user;

          // Fetch IP address AFTER user is authenticated
          let ipAddress = await getIpAddress();

          amplitudeLog({
              data: { 
                  userID: user.uid, 
                  device_id: device_id,
                  ip: ipAddress,
                  email,
                  screenSize: screenSize 
              },
              event: "login_webapp",
          });
      })
      .catch((error) => {
          console.error("Login failed:", error);
          
          amplitudeLog({
            data: { 
                email: email, 
                device_id: device_id,
                screenSize: screenSize 
            },
            event: "login_webapp_err",
        });

          setError("Login failed.");
          setIsLoading(false);
      });
};

  if (isLoading)
    return (
      <div>
        <div className="p-progress-spinner-container">
          <ProgressSpinner />
        </div>
      </div>
    );


  return (
    <>
      <div className="fulscreen-center">
        <form className="auth" onSubmit={login}>
          <Card title="Login">
            <div className="w-100  p-p-2">
              <InputText
                placeholder="E-mail"
                onChange={(e) => setEmail(e.target.value)}
                value={email}
                autoFocus
              />
            </div>

            <div className="w-100 p-p-2 p-w-100">
              <InputText
                placeholder="Password"
                type="password"
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
                value={password}
              />
            </div>
            {/* <ReCAPTCHA
              ref={reCaptcha}
              size="invisible"
              sitekey={SITE_KEY}
              onExpired={e => setToken("")}
            /> */}
            <div className="w-100 p-p-2">
              <Button ref={ref}
                className="w-100 p-p-3 p-button-primary"
                type="button"
                onClick={login}
                label="Login"
              />
            </div>
          </Card>
        </form>

        {error && <div className="notification"> {error} </div>}
      </div>
    </>
  );
}

export default SignInComponent;
