import { db, firestore } from "../../../app/firebase";
import { parseUrl, removeUndefined2 } from "../../../helpers/helpers";

type ActionLocation = "all_posts" | "all_reported" | "new_posts";

interface Props {
  post: any;
  user: { uid: any };
  reason?: { reason_report: any; reason_report_int: any };
  location: ActionLocation;
  verification_url: any;
  profile_pic_url: any;
}

export function keepHistory({
  post,
  user,
  reason = {
    reason_report: "keep",
    reason_report_int: 9,
  },
  location,
  verification_url,
  profile_pic_url
}: Props) {
  const imageID = parseUrl(post.imageUrlPost);
  console.log("post", post)
  if(reason.reason_report === 'delete'){
    return alert("reason can not be delete");
  }
  db.collection("history")
    .doc(imageID ?? user.uid)
    .set(
      {
        post_data: removeUndefined2(post),
        mods: firestore.FieldValue.arrayUnion(user.uid),
        action: "keep",
        edit_counter: firestore.FieldValue.increment(1),
        mod_uid: user.uid,
        ...(!!reason
          ? {
            reason: reason.reason_report,
            reason_int: reason.reason_report_int,
          }
          : {}),
        location,
        profile_pic_url,
        verification_url,
        date: firestore.FieldValue.serverTimestamp(),
        imageID: imageID,
      },
      { merge: true }
    );
}
