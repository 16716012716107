import { db } from "../../../app/firebase";
import firebase from "firebase";
import { setPostText } from "../postsSlice";
import store from "../../../app/store";

interface Props {
    collection: "public_post" | "moderation_post";
    uid: string;
    unread: boolean;
    reported: boolean;
}

export function deletePostText({ collection, uid, unread, reported }: Props) {
    const caption_delete_moderation = firebase
        .functions()
        .httpsCallable("caption_delete_moderation");

    db.collection(collection)
        .doc(uid)
        .set({ postText: "" }, { merge: true })
        .then(() => {
            //   caption_delete_moderation();
            store.dispatch(
                setPostText({
                    postText: "",
                    reported: reported,
                    unread: unread,
                })
            );
        });
}
