import { db } from "../../../../app/firebase";

export const fetchPosts = async (isHistory, mod_uid) => {
    try {
        let res = db.collection('age');
        if (isHistory) {
            res = res.where("waiting_moderation", "==", false);
            if(mod_uid){
                res = res.where("mod_uid", "==", mod_uid);
            }
        } else {
            res = res.where("waiting_moderation", "==", true)
        }

        if (!isHistory) {
            res = res
                .orderBy("priority", "desc")
        }
        if (isHistory) {
            res = res.orderBy("mod_date", "desc")
        } else {
            res = res.orderBy("date", "desc")
        }
        res = await res
            .limit(20)
            .get();

        let lastVisible = res?.docs[res?.docs?.length - 1];
        const postsArray = [];
        res.forEach(post => {
            postsArray.push(post.data())
        });

        return [postsArray, lastVisible]
    } catch (error) {
        console.error("error occured while fetching posts:", error)
    }
}

export const loadAdmins = (setAdmins) => {
    const adminsArray = [];
    db.collection("admin")
      .where("admin", "==", true)
      .get()
      .then((snapshot) => {
        snapshot.forEach((admin) => {
          const { userID } = admin.data();
          adminsArray.push({ label: userID, value: userID });
        });
        setAdmins((admins) => [...admins, ...adminsArray]);
      });
  }

export const fetchNext = async (isHistory, startAfter) => {
    try {
        let res = db.collection('age');
        if (isHistory) {
            res = res.where("waiting_moderation", "==", false);
        } else {
            res = res.where("waiting_moderation", "==", true)
        }
        if (!isHistory) {
            res = res
                .orderBy("priority", "desc")
        }
        if (isHistory) {
            res = res.orderBy("mod_date", "desc")
        } else {
            res = res.orderBy("date", "desc")
        }
        res = await res
            .startAfter(startAfter)
            .limit(20)
            .get();

        let lastVisible = res?.docs[res?.docs?.length - 1];

        const postsArray = [];
        res.forEach(post => {
            postsArray.push(post?.data())
        });
        return [postsArray, lastVisible, res?.docs?.length]
    } catch (error) {
        console.error("error occured while fetching posts:", error)
    }
}

export const ageFlag = (reqAge, aiAge) => {
    let diff = (aiAge - reqAge).toFixed(1);
    if (reqAge < 18) {
        if (diff >= 1) {
            return [true, diff];
        }
    } else {
        if (diff >= 2) {
            return [true, diff];
        }
    }
    return [false, diff];
}

export const resToString = (res) => {
    let str = ""
    if (res === 1) {
        str = "Accepted"
    }
    else if (res === 2) {
        str = "Rejected"
    }
    else if (res === 3) {
        str = "Banned"
    }
    else if (res === 4) {
        str = "Support"
    }
    return str;
}

export function convertSecondsTo(seconds) {
    const referenceDate = new Date(0);
    const utcDate = new Date(referenceDate.getTime() + seconds * 1000);
    const year = utcDate.getFullYear();
    const month = padZero(utcDate.getMonth() + 1);
    const day = padZero(utcDate.getDate());
    let hours = padZero(utcDate.getHours());
    let minutes = padZero(utcDate.getMinutes());
    const sec = padZero(utcDate.getSeconds());
    let ampm = 'am';

    if (hours > 12) {
        hours -= 12;
        ampm = 'pm';
    } else if (hours === 12) {
        ampm = 'pm';
    } else if (hours === 0) {
        hours = 12;
    }

    if (minutes < 10) {
        minutes = '0' + minutes;
    }

    return `${day}/${month}/${year}`;
}

function padZero(num) {
    return num.toString().padStart(2, '0');
}