import { logAnalytics } from "../firebase";

function amplitudeLog({ post, event, location, data }) {
  // props.unread
  // ? "new_posts"
  // : props.reported
  // ? "all_reported"
  // : "all_posts",
  logAnalytics({
    event_type: event,
    ...data,
    // mod_location: "new_posts",
    // userID: post.userID,
    // imageUrlPost: post.imageUrlPost,
    // postText: post.postText,
  })
    .then((ret) => {
      if(event !== "login_webapp"){
        console.log("Succesfull log", ret);
      };
    })
    .catch((err) => console.error("Error with loging to amplitude", err));
}

export default amplitudeLog;
